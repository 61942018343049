class Functions {
    static isString(string) {
        return typeof string === 'string';
    }

    static isEmptyString(string) {
        return Functions.isString(string) && !string.trim().length;
    }

    static isObject(object) {
        if ((object === null) || (object === undefined)) {
            return false;
        }
        return object.constructor === Object;
    }

    static isEmptyObject(object) {
        return Functions.isObject(object) && (Object.keys(object).length === 0);
    }

    static isJsonString(string) {
        if (!string) {
            return false;
        }

        try {
            const json = JSON.parse(string);
            return (typeof json === 'object');
        } catch (e) {
            return false;
        }
    }

    static getDriveImages(image = '') {
        if (image.indexOf('drive.google') !== -1) {
            let driveId = image.split('id=')[1];
            return `https://drive.google.com/thumbnail?id=${driveId}&sz=w1000`;
        } else {
            return image;
        }
    }

    static getDriveDownloadLink(link = '') {
        if (link.indexOf('drive.google') !== -1) {
            let driveId = link.split('id=')[1];
            return `https://docs.google.com/uc?export=download&id=${driveId}`;
        } else {
            return link;
        }
    }

    static getDriveVideos(video = '') {
        if (video.indexOf('drive.google') !== -1) {
            let driveId = video.split('id=')[1];
            return `https://drive.google.com/uc?export=download&id=${driveId}`;
        } else {
            return video;
        }
    }

    static getNumbers(string) {
        return string.replace(/\D/g, '');
    }

    static checkResultsFromPath(item, view) {
        if (decodeURI(window.location.pathname.split("/").pop()) !== view) {
            return ((item.toLowerCase()).indexOf(decodeURI(window.location.pathname.split("/").pop())) !== -1)
        }
        return true;
    }

    static getUniqueValuesForFilters(list, property) {
        let propertyList = [];
        list.map(item => {
            propertyList.push(item[property])
        });
        let uniqueList = [...new Set(propertyList)];
        return uniqueList;
    }

    static checkFilterResults(prop, property, filterList) {
        let validation = false;
        if (filterList && filterList.length){
            filterList.map(item => {
                if (prop[property] === item.value) {
                    validation = true;
                }
            })
        } else {
            validation = true;
        }
        return validation;
    }

    static removeHour(date) {
        return date.split(" ")[0]
    }

    // Busca en una lista el resultado basado en un id
    static findResult(list, id) {
        list.find(item => {
            return id === Functions.getNumbers(item["Marca temporal"]);
        });
    }
}
export default Functions;
