import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import CookieBanner from 'react-cookie-banner';
import { Link } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss";
// pages
import AboutUs from "pincelit/views/AboutUs.js";
import Article from "pincelit/views/Article.js";
import Book from "pincelit/views/Book.js";
import Books from "pincelit/views/Books.js";
import LandingPage from "pincelit/views/LandingPage.js";
import Legal from "pincelit/views/Legal.js";
import Magazine from "pincelit/views/Magazine.js";
import MagazinePost from "pincelit/views/MagazinePost.js";
import Multilateral from "pincelit/views/Multilateral.js";
import News from "pincelit/views/News.js";
import Partners from "pincelit/views/Partners.js";
import Search from "pincelit/views/Search.js";
import Tools from "pincelit/views/Tools.js";
import Tool from "pincelit/views/Tool.js";
import Academics from "pincelit/views/Academics.js";
import AcademicWork from "pincelit/views/AcademicWork.js";
import WhatWeDo from "pincelit/views/WhatWeDo.js";
// services
import { DataContextProvider } from "services/dataContext.js";
// utils
import ScrollToTop from "pincelit/utils/scrollToTop.js";

ReactDOM.render(

  <DataContextProvider>
    <BrowserRouter>
      <ScrollToTop>
        <CookieBanner
        message={`Si quieres usar esta web tendrás que aceptar nuestros términos de uso.`}
        link={<Link to="/legal">Ver términos y condiciones.</Link>}
        buttonMessage="Aceptar"
        dismissOnScroll={false}
        onAccept={() => {}}
        cookie="user-has-accepted-terms-and-conditions" />
        <Switch>
            <Route path="/artículo" render={props => <Article {...props} />} />
            <Route path="/busqueda" render={props => <Search {...props} />} />
            <Route path="/legal" render={props => <Legal {...props} />} />
            <Route path="/libro" render={props => <Book {...props} />} />
            <Route path="/libros" render={props => <Books {...props} />} />
            <Route path="/novedades" render={props => <News {...props} />} />
            <Route path="/partners" render={props => <Partners {...props} />} />
            <Route path="/que-hacemos" render={props => <WhatWeDo {...props} />} />
            <Route path="/quienes-somos" render={props => <AboutUs {...props} />} />
            <Route path="/revista-tip" render={props => <Magazine {...props} />} />
            <Route path="/revista" render={props => <MagazinePost {...props} />} />
            <Route path="/herramientas" render={props => <Tools {...props} />} />
            <Route path="/herramienta" render={props => <Tool {...props} />} />
            <Route path="/multilateral" render={props => <Multilateral {...props} />} />
            <Route path="/trabajos-academicos" render={props => <Academics {...props} />} />
            <Route path="/trabajo-academico" render={props => <AcademicWork {...props} />} />
            <Route
              path="/inicio"
              render={props => <LandingPage {...props} />}
            />
            <Redirect to="/inicio" />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  </DataContextProvider>,
  document.getElementById("root")
);
