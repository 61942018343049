import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dots } from 'react-preloaders';

import { GoogleSpreadsheet } from 'google-spreadsheet';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Badge,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import FixedTransparentNavbar from "pincelit/components/Navbar.js";
import BlogPostsHeader from "pincelit/components/PageHeader.js";
import Footer from "pincelit/components/Footer.js";

function Search() {
  // hardcode values
  const resultsSize = 6;

  // states
  const [results, setResults] = useState([]);
  const [categoryData, setCategoryData] = useState('');
  const [firstFocus, setFirstFocus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [resultsDisplay, setResultsDisplay] = useState(resultsSize - 1);
  const category = (new URLSearchParams(window.location.search)).get('categoría');
  const getDriveImages = function (image = '') {
    if (image.indexOf('drive.google') !== -1) {
      let driveId = image.split('id=')[1];
      return `https://drive.google.com/thumbnail?id=${driveId}&sz=w1000`;
      
    } else {
      return image;
    }
  }
  const checkResults = (item) => {
    return ((item.toLowerCase()).indexOf(decodeURI(window.location.pathname.split("/").pop()).toLowerCase()) !== -1)
  };
  const getNumbers = function (string) {
    return string.replace(/\D/g, '');
  }
  const getMatches = function(array, path, category){
    // eslint-disable-next-line array-callback-return
    array.map((item,i) => {
      if (checkResults(item.Título) || (item.Tags && checkResults(item.Tags)) || (item.Autor && checkResults(item.Autor))){
        if (category){
          item.Categoría = category;
        }
        item.Path = path;
        setResults(results => [...results, item])
      }
    })
  }
  const updateResults = async () => {
    // todo - fix security data
    const creds = require('credentials.json'); // the file saved above
    const doc = new GoogleSpreadsheet('1mUhUCwdD5hUrVaTLQ7nDEeounxlqG5Ggu5R9LgqAsOg');
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    getMatches(await doc.sheetsByIndex[0].getRows(), '/artículo/');
    getMatches(await doc.sheetsByIndex[1].getRows(), '/revista/', 'Revista TIP');
    getMatches(await doc.sheetsByIndex[2].getRows(), '/libro/', 'Libro');
    getMatches(await doc.sheetsByIndex[7].getRows(), '/trabajo-academico/', 'Trabajos académicos');
    setLoading(false);
  };
  React.useEffect(() => {
    updateResults()
    document.body.classList.add("blog-posts");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("blog-posts");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [firstFocus, updateResults]);
  return (
    <>
      <FixedTransparentNavbar />
      <div className="wrapper">
        <BlogPostsHeader image={require("assets/img/default—banner@2x.png")} title="Resultados de búsqueda" />
        <div className="section section-about-us">
          <Container>
            <Row>
              {
                results.map((item, i) =>
                  i <= (resultsDisplay) &&
                  <Col data-js={i} key={i} md="12">
                    <Card className="card-plain card-blog card-search-custom">
                      <Row>
                        <Col md="4">
                          <div className="card-image">
                            <a href={`${item.Path}${getNumbers(item["Marca temporal"])}`}>
                              <div
                                className="img rounded img-raised search-img"
                                style={{
                                  backgroundImage:
                                    "url(" + getDriveImages(item.Portada) + ")"
                                }}
                              ></div>
                            </a>
                          </div>
                        </Col>
                        <Col md="8">
                          <CardBody>
                            <Badge color="primary" className="mr-1">{item.Categoría}</Badge>
                            {
                              item.Tags &&
                              (item.Tags).split(',').map(tag => (
                                <Badge color="info" className="mr-1">{tag}</Badge>
                              ))
                            }
                            <CardTitle tag="h4">
                              <a href={`${item.Path}${getNumbers(item["Marca temporal"])}`}>
                                {item.Título}
                              </a>
                            </CardTitle>
                            {
                              item.Subtítulo &&
                              <div>
                                <p className="card-description">
                                  {(item.Subtítulo).substring(0, 100)}...
                                </p>
                              </div>
                            }
                            <Link to={`${item.Path}${getNumbers(item["Marca temporal"])}`}>
                              <Button
                                size="sm"
                                className="btn-round mt-0 mb-0"
                                color="primary"
                                outline
                              >
                                Leer más
                                </Button>
                            </Link>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                )
              }
              {
                !results.length &&
                <Col className="ml-auto mr-auto" md="8">
                  <div className="section-description text-center">
                    <h4>No se encontraron resultados</h4>
                  </div>
                </Col>
              }
            </Row>
            {
              (resultsDisplay + 1) < results.length &&
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <div className="section-description text-center">
                    <Button
                      className="btn-round"
                      color="info"
                      onClick={() => { setResultsDisplay(resultsDisplay + resultsSize) }}
                    >
                      Ver más
                  </Button>
                  </div>
                </Col>
              </Row>
            }
          </Container>
        </div>
        <Footer />
        <Dots customLoading={loading} color={'#FFFFFF'} />
      </div>
    </>
  );
}

export default Search;
