import React from "react";
import { Link } from "react-router-dom";
import Parser from 'html-react-parser';
import Linkify from 'react-linkify';
// share btns
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Badge,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import FixedTransparentNavbar from "pincelit/components/Navbar.js";
import Footer from "pincelit/components/Footer.js";
import AboutUsHeader from "pincelit/components/PageHeader.js";

// Context
import { DataContextConsumer } from "services/dataContext.js";

// Utils
import Functions from 'pincelit/utils/functions.js';

function Article() {
  // states
  const workId = decodeURI(window.location.pathname.split("/").pop())

  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("blog-posts");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <DataContextConsumer>
      {context => (
        <React.Fragment>
          <FixedTransparentNavbar />
          <div className="wrapper article-styles">
            {
              context.data.trabajos &&
              (context.data.trabajos).map(item =>
                workId === Functions.getNumbers(item["Marca temporal"]) &&
                <React.Fragment>
                  <AboutUsHeader breadcrumbItems={[{ name: 'Trabajos académicos', url: '/trabajos-academicos' }]} image={require("assets/img/default—banner@2x.png")} title={item.Título} subtitle={item.Subtítulo} />
                  <div className="section">
                    <div className="section pt-0">
                      <Container>
                        <Row>
                          <Col className="ml-auto mr-auto mb-3" md="12">
                            <p>
                              Publicado el {item["Marca temporal"] && Functions.removeHour(item["Marca temporal"])}
                            </p>
                          </Col>
                          <Col className="ml-auto mr-auto" sm="12" md="3">
                            <img
                              alt="..."
                              className="img rounded img-raised"
                              src={Functions.getDriveImages(item.Portada)}
                            ></img>

                            {
                              item.Documento &&
                              <Button href={Functions.getDriveDownloadLink(item.Documento)} target="_blank" rel="noopener noreferrer" className="btn-round mt-4 mb-0" color="primary" outline>
                                <i className="fab fas fa-book mr-3"></i>
                                Descargar
                              </Button>
                            }
                            {
                              item.Link &&
                              <Button href={item.Link} target="_blank" rel="noopener noreferrer" className="btn-round ml-1 mt-4 mb-0" color="primary" outline>
                                <i className="fab fas fa-book mr-3"></i>
                                Ver online
                              </Button>
                            }
                          </Col>
                          <Col className="ml-auto mr-auto" sm="12" md="9">
                            <Row>
                              <Col className="small" sm="6" md="6">
                                <div>
                                  <p><b>Autor:</b> {item.Autor}</p>
                                  <p><b>País:</b> {item.País}</p>
                                </div>
                              </Col>
                              <Col className="mt-3" sm="12" md="12">
                                <Linkify properties={{ target: '_blank' }}>
                                  <p>
                                    {Parser(item.Descripción + '')}
                                  </p>
                                </Linkify>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                    <div className="section section-blog-info">
                      <Container>
                        <Row>
                          <Col className="ml-auto mr-auto" md="12">
                            <hr />
                            <Row>
                              <Col md="12">
                                <EmailShareButton url={window.location.href}>
                                  <Button
                                    className="btn-round btn-icon mr-1"
                                    color="google"
                                  >
                                    <i className="now-ui-icons ui-1_email-85"></i>
                                  </Button>
                                </EmailShareButton>
                                <FacebookShareButton url={window.location.href}>
                                  <Button
                                    className="btn-round btn-icon mr-1"
                                    color="facebook"
                                  >
                                    <i className="fab fa-facebook-square"></i>
                                  </Button>
                                </FacebookShareButton>
                                <TwitterShareButton url={window.location.href}>
                                  <Button
                                    className="btn-round btn-icon mr-1"
                                    color="twitter"
                                  >
                                    <i className="fab fa-twitter"></i>
                                  </Button>
                                </TwitterShareButton>
                                <WhatsappShareButton url={window.location.href}>
                                  <Button
                                    className="btn-round btn-icon mr-1"
                                    color="whatsapp"
                                  >
                                    <i className="fab fa-whatsapp"></i>
                                  </Button>
                                </WhatsappShareButton>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="ml-auto mr-auto mt-5" md="12">
                            <h4 className="subtitle">
                              También podrá interesarte
                            </h4>
                          </Col>
                          {
                            context.data.revistas &&
                            (context.data.revistas).slice(0, 3).map((item, i) =>
                              <Col data-js={item.Id} key={item.Id} sm="12" md="6" lg="4">
                                <Card className="card-plain card-blog">
                                  <div className="card-image">
                                    <Link to={`/revista/${Functions.getNumbers(item["Marca temporal"])}`}>
                                      <div
                                        className="img rounded img-raised article-wrapper"
                                      >
                                        <div
                                          className="article-cover"
                                          style={{
                                            backgroundImage:
                                              "url(" + Functions.getDriveImages(item.Portada) + ")"
                                          }}
                                        ></div>
                                        <div
                                          className="article-img"
                                          style={{
                                            backgroundImage:
                                              "url(" + Functions.getDriveImages(item.Portada) + ")"
                                          }}
                                        >

                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                  <CardBody>
                                    <Badge color="info">{item.Categoría}</Badge>
                                    <CardTitle tag="h4">
                                      <Link to={`/revista/${Functions.getNumbers(item["Marca temporal"])}`}>
                                        {item.Título}
                                      </Link>
                                    </CardTitle>
                                    <Link to={`/revista/${Functions.getNumbers(item["Marca temporal"])}`}>
                                      <Button
                                        size="Sm"
                                        className="btn-round mt-0 mb-0"
                                        color="primary"
                                        outline
                                      >
                                        Leer más
                                      </Button>
                                    </Link>
                                  </CardBody>
                                </Card>
                              </Col>
                            )
                          }
                        </Row>
                      </Container>
                    </div>
                  </div>
                </React.Fragment>
              )
            }
            <Footer />
            {/* <Dots customLoading={context.loading} color={'#FFFFFF'} /> */}
          </div>
        </React.Fragment>
      )}
    </DataContextConsumer>
  );
}

export default Article;
