import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

// core components

function AboutUsHeader(props) {
  let pageHeader = React.createRef();
  let backgroundImage = props.image;
  const goBack = (event) => {
    window.history.go(-1);
    event.preventDefault();
  }
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-static page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: `url(${backgroundImage})`
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center">
          <div class="container">
            <Breadcrumb className="breadcrum-custom">
              <BreadcrumbItem><Link to="/inicio">Inicio</Link></BreadcrumbItem>
              {
                props.breadcrumbItems &&
                props.breadcrumbItems.map(item => (
                  <BreadcrumbItem><Link to={item.url}>{item.name}</Link></BreadcrumbItem>
                ))
              }
              <BreadcrumbItem active>{props.title}</BreadcrumbItem>
            </Breadcrumb>
            <Breadcrumb className="mobile-breadcrum-custom">
              <BreadcrumbItem>
                <Link onClick={goBack}><i className="fa fa-arrow-circle-left"></i> Volver</Link>
              </BreadcrumbItem>
            </Breadcrumb>
            <h1 className="title">{props.title}</h1>
            <h4 className="description">
              {props.subtitle}
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUsHeader;
