import React, { useState } from "react";
import { Dots } from 'react-preloaders';
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import FixedTransparentNavbar from "pincelit/components/Navbar.js";
import BlogPostsHeader from "pincelit/components/PageHeader.js";
import Footer from "pincelit/components/Footer.js";

// Context
import { DataContextConsumer } from "services/dataContext.js";

// Utils
import Functions from 'pincelit/utils/functions.js';

function Magazine() {
  // hardcode values
  const magazinesSize = 8;

  // states
  const [magazinesDisplay, setMagazinesDisplay] = useState(magazinesSize - 1);
  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("blog-posts");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <DataContextConsumer>
      {context => (
        <React.Fragment>
          <FixedTransparentNavbar />
          <div className="wrapper magazine-styles">
            <BlogPostsHeader image={require("assets/img/tip—banner@2x.png")} title="Revista TIP" subtitle="Trabajos de Investigación en Paradiplomacia." />
            <div className="section">
              <Container>
                <Row>
                  <Col md="12">
                    <p className="section-description">
                      <strong>TIP</strong> es una revista académica destinada a la difusión de investigaciones sobre las relaciones internacionales de los gobiernos locales y la cooperación descentralizada. Sus autores, académicos de América Latina y Europa, así como su Consejo de Expertos, son el gran motor de la misma. Gracias a los apoyos, la revista es de difusión libre y gratuita.
                </p>
                  </Col>
                </Row>
                <Row>
                  {
                    context.data.revistas &&
                    (context.data.revistas).map((item, i) =>
                      i <= (magazinesDisplay) &&
                      <Col xs="12" sm="6" md="4" lg="3">
                        <Card className="card-plain card-blog">
                          <div className="card-image">
                            <Link to={`/revista/${Functions.getNumbers(item["Marca temporal"])}`}>
                              <div
                                className="img rounded img-raised magazine-img"
                                style={{
                                  backgroundImage:
                                    `url(${Functions.getDriveImages(item.Portada)})`
                                }}
                              ></div>
                            </Link>
                          </div>
                          <CardBody>
                            <Badge color="info">Revista TIP</Badge>
                            <CardTitle>
                              <h4 small>
                                <Link to={`/revista/${Functions.getNumbers(item["Marca temporal"])}`}>
                                  {item.Título}
                                </Link>
                              </h4>
                              <p className="card-description">
                                {item.Subtítulo}
                              </p>
                              <Link className="ml-1" to={`/revista/${Functions.getNumbers(item["Marca temporal"])}`}>
                                <Button
                                  size="sm"
                                  className="btn-round mt-0 mb-0"
                                  color="primary"
                                  outline
                                >
                                  Leer más
                              </Button>
                              </Link>
                            </CardTitle>
                          </CardBody>
                        </Card>
                      </Col>
                    )
                  }
                </Row>
                {
                  (magazinesDisplay + 1) < (context.data.revistas).length &&
                  <Row>
                    <Col className="ml-auto mr-auto" md="8">
                      <div className="section-description text-center">
                        <Button
                          className="btn-round"
                          color="info"
                          onClick={() => { setMagazinesDisplay(magazinesDisplay + magazinesSize) }}
                        >
                          Ver más
                        </Button>
                      </div>
                    </Col>
                  </Row>
                }
              </Container>
            </div>
            <Footer />
            {/* <Dots customLoading={context.loading} color={'#FFFFFF'} /> */}
          </div>
        </React.Fragment>
      )}
    </DataContextConsumer>
  );
}

export default Magazine;
