import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReadingTime from 'reading-time';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import ContentLoader from "react-content-loader"
import axios from "axios"

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DropdownFixedNavbar from "pincelit/components/Navbar.js";
import LandingPageHeader from "pincelit/sections/Hero";
import FooterDefault from "pincelit/components/Footer.js";

// Context
import { DataContextConsumer } from "services/dataContext.js";

// Utils
import Functions from 'pincelit/utils/functions.js';

function LandingPage() {

  // hardcode values
  const newsSize = 2;

  // states
  const [youtubePosts, setYoutubeFeed] = useState([]);

  const getYoutubeFeed = function(){        
    axios.get('https://www.googleapis.com/youtube/v3/search?key=AIzaSyBtHnfT0DWdDidURFhxz3qqpCfw_bYei3s&channelId=UCil_uEstN2muZ4ABI_vL6pA&part=snippet,id&order=date&maxResults=8')
    .then(function (response) {  
      const {data} = response;    
      setYoutubeFeed(data.items)
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    })
  }
  React.useEffect(() => {
    getYoutubeFeed();
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <DataContextConsumer>
      {(context) => (
        <React.Fragment>
          <DropdownFixedNavbar />
          <div className="wrapper">
            {context.data.novedades && context.data.revistas && (
              <LandingPageHeader
                news={context.data.novedades}
                magazine={context.data.revistas[0]}
              />
            )}
            {context.loading && (
              <div>
                <ContentLoader
                  speed={2}
                  width={2000}
                  height={550}
                  viewBox="0 0 2000 550"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="0" ry="0" width="2000" height="550" />
                  <rect x="123" y="120" rx="0" ry="0" width="1" height="1" />
                </ContentLoader>
              </div>
            )}
            {/* <div className="section section-cities pt-0">
              <Container>
                <Row>
                  <Col className="px-2 py-2" md="6">
                    <Card
                      className="card-fashion landing-page-card rounded"
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/Smartcities@2x.png") + ")"
                      }}
                    >
                      <CardTitle tag="div">
                        <i className="custom-icon far fa-lightbulb"></i>
                        <h4 className="title">
                          Smart Cities
                        </h4>
                        <p className="description">Apoyamos a los gobiernos locales en su proceso de transformación para ser más inteligentes con foco en las personas.</p>
                        <ListGroup flush className="mt-4">
                          <ListGroupItem>
                            <Link to="/novedades/experiencias%20smart%20cities">
                              <Button className="btn-round mt-0 mb-0" color="primary" outline>
                                <i className="fab fas fa-clipboard-check mr-3"></i>
                            Experiencia
                          </Button>
                            </Link>
                          </ListGroupItem>
                          <ListGroupItem>
                            <Link to="/libros/smart%20cities">
                              <Button className="btn-round mt-0 mb-0" color="primary" outline>
                                <i className="fab fas fa-book mr-3"></i>
                            Libros
                          </Button>
                            </Link>
                          </ListGroupItem>
                          <ListGroupItem>
                            <Link to="/novedades/capacitaciones%20smart%20cities">
                              <Button className="btn-round mt-0 mb-0" color="primary" outline>
                                <i className="fab fas fa-chalkboard mr-3"></i>
                            Capacitaciones
                          </Button>
                            </Link>
                          </ListGroupItem>
                          <ListGroupItem>
                            <Link to="/novedades/tecnología">
                              <Button className="btn-round mt-0 mb-0" color="primary" outline>
                                <i className="fab fas fa-atom mr-3"></i>
                            Tecnología
                          </Button>
                            </Link>
                          </ListGroupItem>
                        </ListGroup>
                      </CardTitle>
                    </Card>
                  </Col>
                  <Col className="px-2 py-2" md="6">
                    <Card
                      className="card-fashion landing-page-card rounded"
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/Globalcities@2x.png") + ")"
                      }}
                    >
                      <CardTitle tag="div">
                        <i className="custom-icon fas fa-globe"></i>
                        <h4 className="title">
                          Global Cities
                    </h4>
                        <p className="description">Todos los gobiernos locales pueden cooperar internacionalmente y nosotros queremos guiarlos en ese camino de desarrollo.
                      </p>
                        <ListGroup flush className="mt-4">
                          <ListGroupItem>
                            <Link to="/novedades/experiencias%20global%20cities">
                              <Button className="btn-round mt-0 mb-0" color="primary" outline>
                                <i className="fab fas fa-clipboard-check mr-3"></i>
                            Experiencia
                          </Button>
                            </Link>
                          </ListGroupItem>
                          <ListGroupItem>
                            <Link to="/libros/global%20cities">
                              <Button className="btn-round mt-0 mb-0" color="primary" outline>
                                <i className="fab fas fa-book mr-3"></i>
                            Libros
                          </Button>
                            </Link>
                          </ListGroupItem>
                          <ListGroupItem>
                            <Link to="/novedades/capacitaciones%20global%20cities">
                              <Button className="btn-round mt-0 mb-0" color="primary" outline>
                                <i className="fab fas fa-chalkboard mr-3"></i>
                            Capacitaciones
                          </Button>
                            </Link>
                          </ListGroupItem>
                          <ListGroupItem>
                            <Link to="/multilateral">
                              <Button className="btn-round mt-0 mb-0" color="primary" outline>
                                <i className="fab fas fa-project-diagram mr-3"></i>
                              Redes
                              </Button>
                            </Link>
                          </ListGroupItem>
                        </ListGroup>
                      </CardTitle>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div> */}
            <div className="section section-about-us">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-left">
                    <h2 className="title pt-0">Novedades</h2>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  {!context.data.novedades.length && (
                    <>
                      <Col sm="12" md="6" lg="4">
                        <Card className="card-plain card-blog">
                          <CardBody className="ml-auto mr-auto">
                            <ContentLoader
                              width={300}
                              height={500}
                              viewBox="0 0 100 100"
                              style={{ width: "100%" }}
                              speed={2}
                              backgroundColor="#f3f3f3"
                              foregroundColor="#ecebeb"
                            >
                              <rect
                                x="0"
                                y="0"
                                rx="0"
                                ry="0"
                                width="300"
                                height="400"
                              />
                              <rect
                                x="123"
                                y="120"
                                rx="0"
                                ry="0"
                                width="1"
                                height="1"
                              />
                            </ContentLoader>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm="12" md="6" lg="4">
                        <Card className="card-plain card-blog">
                          <CardBody className="ml-auto mr-auto">
                            <ContentLoader
                              width={300}
                              height={500}
                              viewBox="0 0 100 100"
                              style={{ width: "100%" }}
                              speed={2}
                              backgroundColor="#f3f3f3"
                              foregroundColor="#ecebeb"
                            >
                              <rect
                                x="0"
                                y="0"
                                rx="0"
                                ry="0"
                                width="300"
                                height="400"
                              />
                              <rect
                                x="123"
                                y="120"
                                rx="0"
                                ry="0"
                                width="1"
                                height="1"
                              />
                            </ContentLoader>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm="12" md="6" lg="4">
                        <Card className="card-plain card-blog">
                          <CardBody className="ml-auto mr-auto">
                            <ContentLoader
                              width={300}
                              height={500}
                              viewBox="0 0 100 100"
                              style={{ width: "100%" }}
                              speed={2}
                              backgroundColor="#f3f3f3"
                              foregroundColor="#ecebeb"
                            >
                              <rect
                                x="0"
                                y="0"
                                rx="0"
                                ry="0"
                                width="300"
                                height="400"
                              />
                              <rect
                                x="123"
                                y="120"
                                rx="0"
                                ry="0"
                                width="1"
                                height="1"
                              />
                            </ContentLoader>
                          </CardBody>
                        </Card>
                      </Col>
                    </>
                  )}
                  {context.data.novedades &&
                    context.data.novedades.map(
                      (item, i) =>
                        i <= newsSize && (
                          <Col
                            data-js={item.Id}
                            key={item.Id}
                            sm="12"
                            md="6"
                            lg="4"
                          >
                            <Card className="card-plain card-blog">
                              <div className="card-image">
                                <Link
                                  to={`/artículo/${Functions.getNumbers(
                                    item["Marca temporal"]
                                  )}`}
                                >
                                  <div className="img rounded img-raised article-wrapper">
                                    <div
                                      className="article-cover"
                                      style={{
                                        backgroundImage:
                                          "url(" +
                                          Functions.getDriveImages(
                                            item.Portada
                                          ) +
                                          ")",
                                      }}
                                    ></div>
                                    <div
                                      className="article-img"
                                      style={{
                                        backgroundImage:
                                          "url(" +
                                          Functions.getDriveImages(
                                            item.Portada
                                          ) +
                                          ")",
                                      }}
                                    ></div>
                                  </div>
                                </Link>
                              </div>
                              <CardBody>
                                <Badge color="primary" className="mr-2">
                                  {item.Categoría}
                                </Badge>
                                {item.Tags &&
                                  item.Tags.split(",").map((tag) => (
                                    <Link to={`/busqueda/${tag}`}>
                                      <Badge
                                        color="info"
                                        className="mr-2 growth"
                                      >
                                        {tag}
                                      </Badge>
                                    </Link>
                                  ))}
                                <p className="reading-time-text">
                                  {item.Descripción &&
                                    `${Math.ceil(
                                      ReadingTime(item.Descripción).minutes
                                    )} min de lectura`}
                                </p>
                                <CardTitle tag="h4">
                                  <Link
                                    to={`/artículo/${Functions.getNumbers(
                                      item["Marca temporal"]
                                    )}`}
                                  >
                                    {item.Título}
                                  </Link>
                                </CardTitle>
                                <Link
                                  to={`/artículo/${Functions.getNumbers(
                                    item["Marca temporal"]
                                  )}`}
                                >
                                  <Button
                                    size="Sm"
                                    className="btn-round mt-0 mb-0"
                                    color="primary"
                                    outline
                                  >
                                    Leer más
                                  </Button>
                                </Link>
                              </CardBody>
                            </Card>
                          </Col>
                        )
                    )}
                </Row>
                <Row>
                  <Col className="ml-auto mr-auto" md="8">
                    <div className="section-description text-center">
                      <Link to="/novedades">
                        <Button className="btn-round" color="info">
                          Ver más
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div
              className="section subscribe-line-white"
              style={{
                backgroundImage: "url(" + require("assets/img/bg3.png") + ")",
              }}
            >
              <Container>
                <Row>
                  <Col md="6" className="mb-5">
                    <h2 className="title pt-0">Revista TIP</h2>
                    <p className="description">
                      TIP es una revista digital destinada a difundir trabajos
                      de investigación sobre la proyección internacional de los
                      gobiernos subnacionales y los avances en la cooperación
                      descentralizada.
                    </p>
                    <Link to="/revista-tip">
                      <Button className="btn-round" color="info" size="lg">
                        Ver ediciones
                      </Button>
                    </Link>
                  </Col>
                  {context.data.revistas[0] && (
                    <Col md="6" className="magazine-container">
                      <img
                        alt="..."
                        className="img rounded img-raised"
                        src={Functions.getDriveImages(
                          context.data.revistas[0].Portada
                        )}
                      ></img>
                      <p className="description">
                        Última edición - {context.data.revistas[0].Título}
                      </p>
                      <Link
                        to={`/revista/${
                          context.data.revistas[0]["Marca temporal"] &&
                          Functions.getNumbers(
                            context.data.revistas[0]["Marca temporal"]
                          )
                        }`}
                      >
                        <Button
                          size="Sm"
                          className="btn-round mt-0 mb-0"
                          color="primary"
                          outline
                        >
                          Ver revista
                        </Button>
                      </Link>
                    </Col>
                  )}
                </Row>
              </Container>
            </div>
            <div className="section">
              <Container>
                <Row>
                  <Col className="mt-auto mb-auto" md="8">
                    <h2 className="title">Colabore con nosotros</h2>
                    <p className="description">
                      Paradiplomacia.org está abierto a todo tipo de ayuda para
                      el logro de su fin, por lo que todos los aportes son
                      bienvenidos. Para tu tranquilidad, la Asociación está
                      debidamente registrada y controlada por la IGJ y utiliza
                      una de las plataformas de donación más confiables de
                      latinoamérica para asegurar la transparencia de sus
                      ingresos.
                    </p>
                    <Row>
                      <Col md="4">
                        <a href="https://mpago.la/1P1tsLD" target="_blank">
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/meli.png")}
                          ></img>
                        </a>
                      </Col>
                      <Col md="4">
                        <a
                          href="https://donaronline.org/paradiplomacia-org/aporte-voluntario-1"
                          target="_blank"
                        >
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/donar.png")}
                          ></img>
                        </a>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="4" className="ml-auto mr-auto mt-5">
                    <img
                      alt="..."
                      className="img"
                      src={require("assets/img/4.png")}
                    ></img>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="section section-social-networks">
              <Container>
                <Row>
                  <Col sm="6" md="6" className="">
                    <h2 className="title pt-0">
                      <i className="fab fa-twitter mr-3"></i>
                      Twitter
                    </h2>
                    <div className="twitter-wrapper">
                      <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="Paradiplomacia"
                        options={{ tweetLimit: 3 }}
                        noHeader
                        noFooter
                        transparent
                      />
                    </div>
                  </Col>
                  <Col sm="6" md="6">
                    <h2 className="title pt-0">
                      <i className="fab fa-youtube mr-3"></i>
                      Youtube
                    </h2>
                    <Row>
                      {youtubePosts &&
                        youtubePosts.map((video) => {
                          return (
                            <Col sm="12" md="6" className="pb-3">
                              <div class="video-container">
                                <iframe
                                  src={`https://www.youtube.com/embed/${
                                    video.id && video.id.videoId
                                  }`}
                                  class="video"
                                  frameborder="0"
                                  allowfullscreen
                                ></iframe>
                              </div>
                            </Col>
                          );
                        })}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="supportus-line">
              <Container>
                <Row>
                  <Col md="12" className="content-styles">
                    <h3 className="title pt-0">Nos apoyan</h3>
                  </Col>
                  {context.data.partners &&
                    context.data.partners.map(
                      (item, i) =>
                        item.Partner === "Nos apoyan" && (
                          <Col xs="6" sm="6" md="2" className="brand-wrapper">
                            <a
                              href={item.Url}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <img
                                alt="..."
                                className="img"
                                src={Functions.getDriveImages(item.Logo)}
                              ></img>
                            </a>
                          </Col>
                        )
                    )}
                  {!context.data.partners.length && (
                    <>
                      <Col xs="6" sm="6" md="2">
                        <ContentLoader
                          speed={2}
                          width={130}
                          height={130}
                          viewBox="0 0 130 130"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="123"
                            y="120"
                            rx="0"
                            ry="0"
                            width="1"
                            height="1"
                          />
                          <circle cx="65" cy="65" r="65" />
                        </ContentLoader>
                      </Col>
                      <Col xs="6" sm="6" md="2">
                        <ContentLoader
                          speed={2}
                          width={130}
                          height={130}
                          viewBox="0 0 130 130"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="123"
                            y="120"
                            rx="0"
                            ry="0"
                            width="1"
                            height="1"
                          />
                          <circle cx="65" cy="65" r="65" />
                        </ContentLoader>
                      </Col>
                      <Col xs="6" sm="6" md="2">
                        <ContentLoader
                          speed={2}
                          width={130}
                          height={130}
                          viewBox="0 0 130 130"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="123"
                            y="120"
                            rx="0"
                            ry="0"
                            width="1"
                            height="1"
                          />
                          <circle cx="65" cy="65" r="65" />
                        </ContentLoader>
                      </Col>
                      <Col xs="6" sm="6" md="2">
                        <ContentLoader
                          speed={2}
                          width={130}
                          height={130}
                          viewBox="0 0 130 130"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="123"
                            y="120"
                            rx="0"
                            ry="0"
                            width="1"
                            height="1"
                          />
                          <circle cx="65" cy="65" r="65" />
                        </ContentLoader>
                      </Col>
                      <Col xs="6" sm="6" md="2">
                        <ContentLoader
                          speed={2}
                          width={130}
                          height={130}
                          viewBox="0 0 130 130"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="123"
                            y="120"
                            rx="0"
                            ry="0"
                            width="1"
                            height="1"
                          />
                          <circle cx="65" cy="65" r="65" />
                        </ContentLoader>
                      </Col>
                      <Col xs="6" sm="6" md="2">
                        <ContentLoader
                          speed={2}
                          width={130}
                          height={130}
                          viewBox="0 0 130 130"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="123"
                            y="120"
                            rx="0"
                            ry="0"
                            width="1"
                            height="1"
                          />
                          <circle cx="65" cy="65" r="65" />
                        </ContentLoader>
                      </Col>
                    </>
                  )}
                  <Col className="ml-auto mr-auto mt-4" md="12">
                    <div className="section-description text-center">
                      <Link to="/partners">
                        <Button className="btn-round" color="info">
                          Ver más
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <FooterDefault />
          </div>
          {/* <Dots customLoading={context.loading} color={'#FFFFFF'} /> */}
        </React.Fragment>
      )}
    </DataContextConsumer>
  );
}

export default LandingPage;
