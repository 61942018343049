import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
    Button,
    Container,
    Badge,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselIndicators
} from "reactstrap";

// core components

function Header(props) {
    const { news, magazine } = props;
    const carouselSize = 4;
    const newsData = (news.slice(0, carouselSize));
    newsData[4] = magazine;
    // navbar collapses states and functions
    const [navbarOpen1, setNavbarOpen1] = React.useState(false);
    const [navbarOpen2, setNavbarOpen2] = React.useState(false);
    const [navbarOpen3, setNavbarOpen3] = React.useState(false);
    // header 3 carousel states and functions
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === newsData.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? newsData.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = newIndex => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    const getNumbers = function (string) {
        return string.replace(/\D/g, '');
    }
    const getDriveImages = function (image) {
        if (image && image.indexOf('drive.google') !== -1) {
            let driveId = image.split('id=')[1];
            return `https://drive.google.com/thumbnail?id=${driveId}&sz=w1000`;
        } else {
            return image;
        }
    }
    return (
        <>
            {navbarOpen1 || navbarOpen2 || navbarOpen3 ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setNavbarOpen1(false);
                        setNavbarOpen2(false);
                        setNavbarOpen3(false);
                    }}
                />
            ) : null}
            <div className="cd-section" id="headers">
                <div className="header-3">
                    <Carousel styles="z-index:9999;" activeIndex={activeIndex} next={next} previous={previous} interval="7000">
                        <CarouselIndicators
                            items={newsData}
                            activeIndex={activeIndex}
                            onClickHandler={goToIndex}
                            color="primary"
                        />
                        {
                            newsData.length
                            && newsData.map((item, i) => {
                                return (
                                    <CarouselItem
                                        onExiting={onExiting}
                                        onExited={onExited}
                                        key={i}
                                    >
                                        <div className="page-header page-header-medium header-filter">
                                            <div
                                                className="page-header-image hero"
                                                style={{
                                                    backgroundImage: item.Imagen ? `url("${getDriveImages(item.Imagen)}")` : `url("${getDriveImages(item.Fondo)}")`
                                                }}
                                            ></div>
                                            <div className="content">
                                                <Container className="text-left px-5">
                                                    <Row>
                                                        <Col className="text-left mt-auto mb-auto" md="8">
                                                            <Badge color="primary" className="mr-2">{item.Categoría}</Badge>
                                                            {
                                                                item.Tags &&
                                                                (item.Tags).split(',').map(tag => (
                                                                    <Link to={`/busqueda/${tag}`}>
                                                                        <Badge color="info" className="mr-2 growth">{tag}</Badge>
                                                                    </Link>
                                                                ))
                                                            }
                                                            <h1 className="title mt-3">{item.Título}</h1>
                                                            <p className="description">
                                                                {item.Subtítulo}
                                                            </p>
                                                            <br></br>
                                                            <div className="buttons">
                                                                {
                                                                    item.a1Range && (item.a1Range).indexOf('Revistas TIP') !== -1 ?
                                                                        <Link to={`/revista/${item["Marca temporal"] && getNumbers(item["Marca temporal"])}`}>
                                                                            <Button size="lg" className="btn-round mt-0 mb-0" color="primary" outline>
                                                                                <i className="now-ui-icons files_single-copy-04 mr-1"></i> Ver revista
                                    </Button>
                                                                        </Link>
                                                                        :
                                                                        <Link to={`/artículo/${item["Marca temporal"] && getNumbers(item["Marca temporal"])}`}>
                                                                            <Button size="lg" className="btn-round mt-0 mb-0" color="primary" outline>
                                                                                <i className="now-ui-icons files_single-copy-04 mr-1"></i> Leer más ...
                                    </Button>
                                                                        </Link>

                                                                }
                                                            </div>
                                                        </Col>
                                                        {
                                                            item.a1Range && (item.a1Range).indexOf('Revistas TIP') !== -1 &&
                                                            <Col className="text-left" md="4">
                                                                <img
                                                                    alt="..."
                                                                    className="img rounded img-raised magazine-img mt-3 mb-3"
                                                                    src={getDriveImages(item.Portada)}
                                                                ></img>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Container>
                                            </div>
                                        </div>
                                    </CarouselItem>
                                );
                            })
                        }
                        <a
                            className="left carousel-control carousel-control-prev"
                            data-slide="prev"
                            href="#pablo"
                            onClick={e => {
                                e.preventDefault();
                                previous();
                            }}
                            role="button"
                        >
                            <span className="now-ui-icons arrows-1_minimal-left" />
                            <span className="sr-only">Previous</span>
                        </a>
                        <a
                            className="right carousel-control carousel-control-next"
                            data-slide="next"
                            href="#pablo"
                            onClick={e => {
                                e.preventDefault();
                                next();
                            }}
                            role="button"
                        >
                            <span className="now-ui-icons arrows-1_minimal-right" />
                            <span className="sr-only">Next</span>
                        </a>
                    </Carousel>
                </div>
            </div>
        </>
    );
}

export default Header;
