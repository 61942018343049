import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";
import PageHeader from "pincelit/components/PageHeader.js";

// core components
import DropdownFixedNavbar from "pincelit/components/Navbar.js";
import FooterDefault from "pincelit/components/Footer.js";

function Legal() {

  // states
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <DropdownFixedNavbar />
      <div className="wrapper">
        <PageHeader image={require("assets/img/default—banner@2x.png")} title="Términos y Condiciones de Uso" subtitle="Aviso legal." />
        <div className="section pt-5 mb-5">
          <div className="legal-styles">
            <Container>
              <Row>
                <Col className="" md="12">
                  <h3 className="title">1) Condiciones Generales de Uso del sitio web www.paradiplomacia.org.</h3>
                  <p className="description">
                  El portal web WWW.PARADIPLOMACIA.ORG es titularidad de Paradiplomacia.org
                  El acceso a la plataforma web implica la aceptación del usuario/a de las normas de uso que se establecen para el mismo, así como la aceptación del contenido de la cláusula de exención de responsabilidad y de los Derechos de la Propiedad Intelectual e Industrial aplicable. El usuario/a deberá consultar las condiciones específicas establecidan en presente aviso legal para el acceso y uso de determinados servicios dentro del presente sitio web. El acceso a dichos servicios implica igualmente la aceptación de dichas cláusulas por el usuario/a.                  </p>
                </Col>
                <Col className="" md="12">
                  <h3 className="title">2) Derechos de Propiedad Intelectual e Industrial.</h3>
                  <p className="description">
                    La reproducción total o parcial del contenido de la página web se limita a la autorización expresa de Paradiplomacia.org. El usuario/a de la página web se compromete a no reproducir, copiar, distribuir, transformar o modificar los contenidos, incluídos marcas o signos distintivos, ya sean titularidad de PARADIPLOMACIA.ORG o de terceros, a menos que cuente con la autorización del titular de los derechos, ni a suprimir, manipular o de cualquier forma alterar el "copyright".                 </p>
                </Col>
                <Col className="" md="12">
                  <h3 className="title">3) Exención de Responsabilidad.</h3>
                  <p className="description">
                    En la página web se alberga información proveniente tanto de fuentes propias como de terceros, con un objetivo puramente comunicacional quedando eximido Paradiplomacia.org de cualquier responsabilidad que se derive de la inexactitud de dichos contenidos. El acceso es gratuito y no genera relación comercial, contractual o profesional alguna entre sus usuarios/as y Paradiplomacia.org.
                    En la página web se incluyen enlaces e hipervínculos a otras páginas web que posibilitan el acceso al usuario/a a información y/o servicios ofrecidos por terceros y que no pertenecen ni se encuentran bajo el control de Paradiplomacia.org. Por este motivo, Paradiplomacia.org no se responsabiliza de la información contenida en los mismos.
                    Paradiplomacia.org no responderá en ningún caso del incumplimiento de cualquier norma aplicable en que pueda incurrir el usuario/a ya sea en el acceso a la página web o en el uso de las informaciones contenidas en este, así como en el acceso a los enlaces e hipervínculos contenidos en la plataforma.
                    Paradiplomacia.org podrá modificar, sin previo aviso, la información contenida en la página web, así como su configuración y presentación.
Paradiplomacia.org puede almacenar información de seguimiento de la actividad del usuario/a en la sección "Comunidad Paradiplomática" o en la sección que Paradiplomacia.org lo determine. El usuario/a autoriza a Paradiplomacia.orga utilizar dicha información para acciones de marketing, generación de estadísticas y mejora.                 </p>
                </Col>
                <Col className="" md="12">
                  <h3 className="title">4) Protección de Datos.</h3>
                  <p className="description">
                    Al facilitar el interesado sus datos de carácter personal y de correo electrónico, y datos suministrados en las diferentes secciones y subsecciones del portal web Paradiplomacia.org, expresamente autoriza la utilización de dichos datos a los efectos de las comunicaciones periódicas, incluyendo explícitamente las que se realicen por correo electrónico informándoles de sus actividades y noticias, cursos, publicación de libros, programas así como cualquier oferta de servicios y productos relacionados con la actividad institucional de Paradiplomacia.org.
Ante cualquier consulta, el cesionario puede ejercitar respecto a sus datos los derechos de oposición, acceso, rectificación y cancelación mediante carta dirigida Paradiplomacia.org al correo electrónico info@paradiplomacia.org.         </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FooterDefault />
      </div>

    </>
  );
}

export default Legal;
