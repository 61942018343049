import React, { useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Button,
  Input,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";

function ScrollTransparentNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navbarColor, setNavbarColor] = React.useState(
    (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499
      ? ""
      : " navbar-transparent"
  );
  const [buyButtonColor, setBuyButtonColor] = React.useState(
    (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499
      ? "info"
      : "neutral"
  );
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value)
  }
  const handleSidebar = () => {
    if (collapseOpen) {
      document.documentElement.classList.toggle("nav-open");
      setCollapseOpen(!collapseOpen);
    }
    // document.documentElement.classList.toggle("nav-open");
    // setCollapseOpen(!collapseOpen);
  }

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
        setBuyButtonColor("info");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor(" navbar-transparent");
        setBuyButtonColor("neutral");
      }
    };
    const resizeHeaderOnScroll = () => {
      const distanceY = window.pageYOffset || document.documentElement.scrollTop,
        shrinkOn = 200,
        headerEl = document.getElementById("js-header");

      if (distanceY > shrinkOn) {
        headerEl.classList.add("smaller-header");
      } else {
        headerEl.classList.remove("smaller-header");
      }
    }
    window.addEventListener("scroll", resizeHeaderOnScroll);
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="bg-white fixed-top" expand="lg" id="js-header">
        <Container className="navbar-top-styles">
          <div className="navbar-translate">
            <NavbarBrand to="/inicio" tag={Link} id="navbar-brand">
              <img
                alt="..."
                className="img"
                src={require("assets/img/logo.png")}
              ></img>
            </NavbarBrand>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto socialnetwork-items" id="ceva" navbar>
              {/* <NavItem>
                <NavLink
                  href="/inicio"
                >
                  Inicio
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/que-es-el-repuesto"
                >
                  ¿Qué es El Repuesto?
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink1"
                  nav
                  onClick={e => e.preventDefault()}
                >
                  <i className="now-ui-icons files_single-copy-04"></i>
                  <p>Noticias</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                  {
                    categories.length && categories.map((item, i) =>
                      <DropdownItem href={`/noticias?categoría=${item.Categoría}`}>
                        {item.Categoría}
                      </DropdownItem>
                    )
                  }
                </DropdownMenu>
              </UncontrolledDropdown> */}
              <NavItem>
                <NavLink
                  href="mailto:info@paradiplomacia.org"
                >
                  <i className="fab fas fa-envelope"></i>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.instagram.com/paradiplomacia/"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.facebook.com/paradiplomacia.org/"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square"></i>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://twitter.com/Paradiplomacia"
                  target="_blank"
                >
                  <i className="fab fa-twitter"></i>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.linkedin.com/company/paradiplomacia.org/about/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin"></i>
                </NavLink>
              </NavItem>
              <NavItem>
                <Form className="form-inline ml-auto" action={`/busqueda/${searchValue}`}>
                  <FormGroup>
                    <Input placeholder="Buscar..." type="text" onChange={handleSearch}></Input>
                  </FormGroup>
                  <Button className="btn-link" color="primary" outline type="submit">
                    <i className="fa fa-search"></i>
                  </Button>
                </Form>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
        <div className="navbar-options-styles">
          <Container>
            <Collapse isOpen={collapseOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem className="ml-4 mobile-search">
                  <Form className="form-inline ml-auto" action={`/busqueda/${searchValue}`}>
                    <FormGroup>
                      <Input placeholder="Buscar..." type="text" onChange={handleSearch}></Input>
                    </FormGroup>
                    <Button className="btn-link" color="primary" outline type="submit">
                      <i className="fa fa-search"></i>
                    </Button>
                  </Form>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/inicio"
                    tag={Link}
                    onClick={() => {
                      handleSidebar();
                    }}
                    className="a-link"
                  >
                    Inicio
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink1"
                    nav
                    onClick={e => e.preventDefault()}
                  >
                    <p>Institucional</p>
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                    <DropdownItem
                      to="/quienes-somos"
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                    >
                      Nosotros
                    </DropdownItem>
                    <DropdownItem
                      to="/que-hacemos"
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                    >
                      ¿Qué hacemos?
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink
                    to="/novedades"
                    tag={Link}
                    onClick={() => {
                      handleSidebar();
                    }}
                    className="a-link"
                  >
                    Novedades
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink1"
                    nav
                    onClick={e => e.preventDefault()}
                  >
                    <p>Global Cities</p>
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                    <DropdownItem
                      to="/novedades/experiencias%20global%20cities"
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                    >
                      Experiencias
                    </DropdownItem>
                    <DropdownItem
                      to="/novedades/global%20city"
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                    >
                      Novedades
                    </DropdownItem>
                    <DropdownItem
                      to="/multilateral"
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                    >
                      Redes
                    </DropdownItem>
                    <DropdownItem
                      to="/herramientas/capacitaciones%20global%20cities"
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                    >
                      Capacitaciones
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink1"
                    nav
                    onClick={e => e.preventDefault()}
                  >
                    <p>Smart Cities</p>
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                    <DropdownItem
                      to="/novedades/experiencias%20smart%20cities"
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                    >
                      Experiencias
                    </DropdownItem>
                    <DropdownItem
                      to="/novedades/smart%20city"
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                    >
                      Novedades
                    </DropdownItem>
                    {/* <DropdownItem
                      to="/libros/smart%20cities"
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                    >
                      Libros
                    </DropdownItem> */}
                    <DropdownItem
                      to="/novedades/tecnología"
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                    >
                      Tecnología
                    </DropdownItem>
                    <DropdownItem
                      to="/herramientas/capacitaciones%20smart%20cities"
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                    >
                      Capacitaciones
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink1"
                    nav
                    onClick={e => e.preventDefault()}
                  >
                    <p>Biblioteca</p>
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                    <DropdownItem
                      to="/revista-tip"
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                    >
                      Revista TIP
                    </DropdownItem>
                    <DropdownItem
                      to="/libros"
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                    >
                      Libros
                    </DropdownItem>
                    <DropdownItem
                      to="/trabajos-academicos"
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                    >
                      Trabajos académicos
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink
                    className="a-link"
                    to="/herramientas"
                    tag={Link}
                    onClick={() => {
                      handleSidebar();
                    }}
                  >
                    Herramientas
                  </NavLink>
                </NavItem>
                {/* <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink1"
                    nav
                    onClick={e => e.preventDefault()}
                  >
                    <p>Mundo Multilateral</p>
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                    <DropdownItem
                      to=""
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                      disabled
                    >
                      Redes de Ciudades
                      <Badge className="ml-1" color="info">Próximamente</Badge>
                    </DropdownItem>
                    <DropdownItem
                      to=""
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                      disabled
                    >
                      Asociación de Municipios
                      <Badge className="ml-1" color="info">Próximamente</Badge>
                    </DropdownItem>
                    <DropdownItem
                      to=""
                      tag={Link}
                      onClick={() => {
                      handleSidebar();
                    }}
                      disabled
                    >
                      Agencias de cooperación
                      <Badge className="ml-1" color="info">Próximamente</Badge>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                <NavItem>
                  <NavLink
                    className="a-link"
                    to="/multilateral"
                    tag={Link}
                    onClick={() => {
                      handleSidebar();
                    }}
                  >
                    Mundo Multilateral
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="a-link"
                    to="/partners"
                    tag={Link}
                    onClick={() => {
                      handleSidebar();
                    }}
                  >
                    Partners
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </div>
      </Navbar>
    </>
  );
}

export default ScrollTransparentNavbar;
