import React from "react";
import { Link } from "react-router-dom";
import Parser from 'html-react-parser';
import { Dots } from 'react-preloaders';
import Linkify from 'react-linkify';

// share btns
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Badge,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import FixedTransparentNavbar from "pincelit/components/Navbar.js";
import Footer from "pincelit/components/Footer.js";
import AboutUsHeader from "pincelit/components/PageHeader.js";

// Context
import { DataContextConsumer } from "services/dataContext.js";

// Utils
import Functions from 'pincelit/utils/functions.js';

function Tools() {
  // states
  const toolId = decodeURI(window.location.pathname.split("/").pop());

  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("blog-posts");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <DataContextConsumer>
      {context => (
        <React.Fragment>
          <FixedTransparentNavbar />
          <div className="wrapper article-styles">
            {/* <BlogPostsHeader image={categoryData.Imagen} title={`Noticias`} subtitle={categoryData.Categoría} /> */}
            {
              context.data.herramientas &&
              (context.data.herramientas).map(item =>
                toolId === Functions.getNumbers(item["Marca temporal"]) &&
                < >
                  <AboutUsHeader breadcrumbItems={[{ name: 'Herramientas', url: '/herramientas' }, { name: item.Categoría, url: `/herramientas/${(item.Categoría).toLowerCase()}` }]} image={require("assets/img/default—banner@2x.png")} title={item.Título} subtitle={item.Subtítulo} />
                  <div className="section">
                    <div className="section pt-0 pb-0">
                      <Container>
                        <Row>
                          <React.Fragment>
                            <Col className="ml-auto mr-auto" md="12">
                              <p>
                                  Publicado el {item["Marca temporal"] && Functions.removeHour(item["Marca temporal"])}
                              </p>
                              <img
                                alt="..."
                                className="img rounded img-raised mb-5"
                                  src={Functions.getDriveImages(item.Portada)}
                              ></img>
                            </Col>
                            <Col className="ml-auto mr-auto" sm="12">
                              <Row>
                                <Col className="small" sm="6" md="4">
                                  <div>
                                    <p><b>Plataforma:</b> {item.Plataforma ? item.Plataforma : 'Sin información'}</p>
                                    <p><b>Valor:</b> {item.Valor ? item.Valor : 'Sin información'}</p>
                                  </div>
                                </Col>
                                <Col className="small" sm="6" md="4">
                                  <div>
                                    <p><b>Idioma:</b> {item.Idioma ? item.Idioma : 'Sin información'}</p>
                                    <p><b>País:</b> {item.País ? item.País : 'Sin información'}</p>
                                  </div>
                                </Col>
                                <Col className="mt-3" sm="12" md="12">
                                  <hr />
                                  <Linkify properties={{ target: '_blank' }}>
                                    <p>
                                      {Parser(item.Descripción + '')}
                                    </p>
                                  </Linkify>
                                </Col>
                              </Row>
                            </Col>
                          </React.Fragment>
                        </Row>
                      </Container>
                    </div>
                    <div className="section section-blog-info">
                      <Container>
                        <Row>
                          <Col className="ml-auto mr-auto" md="12">
                            <Row>
                              <Col md="12">
                                <hr />
                                <EmailShareButton url={window.location.href}>
                                  <Button
                                    className="btn-round btn-icon mr-1"
                                    color="google"
                                  >
                                    <i className="now-ui-icons ui-1_email-85"></i>
                                  </Button>
                                </EmailShareButton>
                                <FacebookShareButton url={window.location.href}>
                                  <Button
                                    className="btn-round btn-icon mr-1"
                                    color="facebook"
                                  >
                                    <i className="fab fa-facebook-square"></i>
                                  </Button>
                                </FacebookShareButton>
                                <TwitterShareButton url={window.location.href}>
                                  <Button
                                    className="btn-round btn-icon mr-1"
                                    color="twitter"
                                  >
                                    <i className="fab fa-twitter"></i>
                                  </Button>
                                </TwitterShareButton>
                                <WhatsappShareButton url={window.location.href}>
                                  <Button
                                    className="btn-round btn-icon mr-1"
                                    color="whatsapp"
                                  >
                                    <i className="fab fa-whatsapp"></i>
                                  </Button>
                                </WhatsappShareButton>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="ml-auto mr-auto mt-5" md="12">
                            <h4 className="subtitle">
                              También podrá interesarte
                            </h4>
                          </Col>
                          {
                            context.data.novedades &&
                            (context.data.novedades).slice(0, 3).map((item, i) =>
                              toolId !== Functions.getNumbers(item["Marca temporal"]) &&
                              <Col data-js={item.Id} key={item.Id} sm="12" md="6" lg="4">
                                <Card className="card-plain card-blog">
                                  <div className="card-image">
                                    <Link to={`/artículo/${Functions.getNumbers(item["Marca temporal"])}`}>
                                      <div
                                        className="img rounded img-raised article-wrapper"
                                      >
                                        <div
                                          className="article-cover"
                                          style={{
                                            backgroundImage:
                                              "url(" + Functions.getDriveImages(item.Portada) + ")"
                                          }}
                                        ></div>
                                        <div
                                          className="article-img"
                                          style={{
                                            backgroundImage:
                                              "url(" + Functions.getDriveImages(item.Portada) + ")"
                                          }}
                                        >

                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                  <CardBody>
                                    <Badge color="info">{item.Categoría}</Badge>
                                    <CardTitle tag="h4">
                                      <Link to={`/artículo/${Functions.getNumbers(item["Marca temporal"])}`}>
                                        {item.Título}
                                      </Link>
                                    </CardTitle>
                                    <Link to={`/artículo/${Functions.getNumbers(item["Marca temporal"])}`}>
                                      <Button
                                        size="Sm"
                                        className="btn-round mt-0 mb-0"
                                        color="primary"
                                        outline
                                      >
                                        Leer más
                                    </Button>
                                    </Link>
                                  </CardBody>
                                </Card>
                              </Col>
                            )
                          }
                        </Row>
                      </Container>
                    </div>
                  </div>
                </ >
              )
            }
            <Footer />
            {/* <Dots customLoading={context.loading} color={'#FFFFFF'} /> */}
          </div>
        </React.Fragment>
      )}
    </DataContextConsumer>
  );
}

export default Tools;
