import React, { useState } from "react";
// react plugin used to create DropdownMenu for selecting items

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DropdownFixedNavbar from "pincelit/components/Navbar.js";
import AboutUsHeader from "pincelit/components/PageHeader.js";
import Footer from "pincelit/components/Footer.js";

// Context
import { DataContextConsumer } from "services/dataContext.js";

// Utils
import Functions from 'pincelit/utils/functions.js';

function Multilateral() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <DataContextConsumer>
      {context => (
        <React.Fragment>
          <DropdownFixedNavbar />
          <div className="wrapper about-us-styles">
            <AboutUsHeader image={require("assets/img/banners/4.png")} title="Cooperación multilateral" subtitle="Las organizaciones potencian las oportunidades de cooperar." />
            <div className="section pb-6" id="equipo">
              <div className="about-team team-4 pb-0 pt-0">
                <Container>
                  <Row id="redes">
                    <Col className="mb-3" md="12">
                      <p className="mb-5">
                        Esta sección está dedicada al universo multilateral de las relaciones internacionales. Los gobiernos locales se asocian para lograr metas en común y compartir buenas prácticas.
                      </p>
                      <h2 className="title pt-0">Redes De Ciudades</h2>
                      <div className="supportus-line">
                        <Container>
                          <Row>
                            {
                              context.data.multilateral ?
                                (context.data.multilateral).map((item, i) =>
                                  item["¿Qué tipo es?"] === 'Red de Ciudades' &&
                                  <Col xs="6" sm="6" md="2" className="brand-wrapper">
                                    <a href={item["URL"]} rel="noopener noreferrer" target="_blank">
                                      <img
                                        alt="..."
                                        className="img"
                                        src={Functions.getDriveImages(item["Logo"])}
                                      ></img>
                                    </a>
                                  </Col>
                                ) :
                                <Col className="ml-auto mr-auto" md="8">
                                  <div className="section-description text-center">
                                    <h4>No se encontraron resultados</h4>
                                  </div>
                                </Col>
                            }
                          </Row>
                        </Container>
                      </div>
                    </Col>
                  </Row>
                  <Row id="asociación">
                    <Col className="mb-3" md="12">
                      <h2 className="title">Asociación De Municipios</h2>
                      <div className="supportus-line">
                        <Container>
                          <Row>
                            {
                              context.data.multilateral ?
                                (context.data.multilateral).map((item, i) =>
                                  item["¿Qué tipo es?"] === 'Asociación de Municipios' &&
                                  <Col xs="6" sm="6" md="2" className="brand-wrapper">
                                    <a href={item["URL"]} rel="noopener noreferrer" target="_blank">
                                      <img
                                        alt="..."
                                        className="img"
                                        src={Functions.getDriveImages(item["Logo"])}
                                      ></img>
                                    </a>
                                  </Col>
                                ) :
                                <Col className="ml-auto mr-auto" md="8">
                                  <div className="section-description text-center">
                                    <h4>No se encontraron resultados</h4>
                                  </div>
                                </Col>
                            }
                          </Row>
                        </Container>
                      </div>
                    </Col>
                  </Row>
                  <Row id="agencias">
                    <Col className="mb-3" md="12">
                      <h2 className="title">Agencias De Cooperación</h2>
                      <div className="supportus-line">
                        <Container>
                          <Row>
                            {
                              context.data.multilateral ?
                                (context.data.multilateral).map((item, i) =>
                                  item["¿Qué tipo es?"] === 'Agencias de Cooperación' &&
                                  <Col xs="6" sm="6" md="2" className="brand-wrapper">
                                    <a href={item["URL"]} rel="noopener noreferrer" target="_blank">
                                      <img
                                        alt="..."
                                        className="img"
                                        src={Functions.getDriveImages(item["Logo"])}
                                      ></img>
                                    </a>
                                  </Col>
                                ) :
                                <Col className="ml-auto mr-auto" md="8">
                                  <div className="section-description text-center">
                                    <h4>No se encontraron resultados</h4>
                                  </div>
                                </Col>
                            }
                          </Row>
                        </Container>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <Footer />
            {/* <Dots customLoading={context.loading} color={'#FFFFFF'} /> */}
          </div>
        </React.Fragment>
      )}
    </DataContextConsumer>
  );
}

export default Multilateral;
