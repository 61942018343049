import React from "react";
import { Link } from "react-router-dom";

// Reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

// Components
import DropdownFixedNavbar from "pincelit/components/Navbar.js";
import AboutUsHeader from "pincelit/components/PageHeader.js";
import Footer from "pincelit/components/Footer.js";

// Context
import { DataContextConsumer } from "services/dataContext.js";

// Utils
import Functions from 'pincelit/utils/functions.js';

function AboutUs() {
  const staticFlags = {
    'Afganistán': 'AF',
    'Albania': 'AL',
    'Alemania': 'DE',
    'Andorra': 'AD',
    'Angola': 'AO',
    'Anguila': 'AI',
    'Antártida': 'AQ',
    'Antigua y Barbuda': 'AG',
    'Arabia Saudita': 'SA',
    'Argelia': 'DZ',
    'Argentina': 'AR',
    'Armenia': 'AM',
    'Aruba': 'AW',
    'Australia': 'AU',
    'Austria': 'AT',
    'Azerbaiyán': 'AZ',
    'Bélgica': 'BE',
    'Bahamas': 'BS',
    'Bahrein': 'BH',
    'Bangladesh': 'BD',
    'Barbados': 'BB',
    'Belice': 'BZ',
    'Benín': 'BJ',
    'Bhután': 'BT',
    'Bielorrusia': 'BY',
    'Birmania': 'MM',
    'Bolivia': 'BO',
    'Bosnia y Herzegovina': 'BA',
    'Botsuana': 'BW',
    'Brasil': 'BR',
    'Brunéi': 'BN',
    'Bulgaria': 'BG',
    'Burkina Faso': 'BF',
    'Burundi': 'BI',
    'Cabo Verde': 'CV',
    'Camboya': 'KH',
    'Camerún': 'CM',
    'Canadá': 'CA',
    'Chad': 'TD',
    'Chile': 'CL',
    'China': 'CN',
    'Chipre': 'CY',
    'Ciudad del Vaticano': 'VA',
    'Colombia': 'CO',
    'Comoras': 'KM',
    'República del Congo': 'CG',
    'República Democrática del Congo': 'CD',
    'Corea del Norte': 'KP',
    'Corea del Sur': 'KR',
    'Costa de Marfil': 'CI',
    'Costa Rica': 'CR',
    'Croacia': 'HR',
    'Cuba': 'CU',
    'Curazao': 'CW',
    'Dinamarca': 'DK',
    'Dominica': 'DM',
    'Ecuador': 'EC',
    'Egipto': 'EG',
    'El Salvador': 'SV',
    'Emiratos Árabes Unidos': 'AE',
    'Eritrea': 'ER',
    'Eslovaquia': 'SK',
    'Eslovenia': 'SI',
    'España': 'ES',
    'Estados Unidos de América': 'US',
    'Estonia': 'EE',
    'Etiopía': 'ET',
    'Filipinas': 'PH',
    'Finlandia': 'FI',
    'Fiyi': 'FJ',
    'Francia': 'FR',
    'Gabón': 'GA',
    'Gambia': 'GM',
    'Georgia': 'GE',
    'Ghana': 'GH',
    'Gibraltar': 'GI',
    'Granada': 'GD',
    'Grecia': 'GR',
    'Groenlandia': 'GL',
    'Guadalupe': 'GP',
    'Guam': 'GU',
    'Guatemala': 'GT',
    'Guayana Francesa': 'GF',
    'Guernsey': 'GG',
    'Guinea': 'GN',
    'Guinea Ecuatorial': 'GQ',
    'Guinea-Bissau': 'GW',
    'Guyana': 'GY',
    'Haití': 'HT',
    'Honduras': 'HN',
    'Hong kong': 'HK',
    'Hungría': 'HU',
    'India': 'IN',
    'Indonesia': 'ID',
    'Irán': 'IR',
    'Irak': 'IQ',
    'Irlanda': 'IE',
    'Isla Bouvet': 'BV',
    'Isla de Man': 'IM',
    'Isla de Navidad': 'CX',
    'Isla Norfolk': 'NF',
    'Islandia': 'IS',
    'Islas Bermudas': 'BM',
    'Islas Caimán': 'KY',
    'Islas Cocos (Keeling)': 'CC',
    'Islas Cook': 'CK',
    'Islas de Åland': 'AX',
    'Islas Feroe': 'FO',
    'Islas Georgias del Sur y Sandwich del Sur': 'GS',
    'Islas Heard y McDonald': 'HM',
    'Islas Maldivas': 'MV',
    'Islas Malvinas': 'FK',
    'Islas Marianas del Norte': 'MP',
    'Islas Marshall': 'MH',
    'Islas Pitcairn': 'PN',
    'Islas Salomón': 'SB',
    'Islas Turcas y Caicos': 'TC',
    'Islas Ultramarinas Menores de Estados Unidos': 'UM',
    'Islas Vírgenes Británicas': 'VG',
    'Islas Vírgenes de los Estados Unidos': 'VI',
    'Israel': 'IL',
    'Italia': 'IT',
    'Jamaica': 'JM',
    'Japón': 'JP',
    'Jersey': 'JE',
    'Jordania': 'JO',
    'Kazajistán': 'KZ',
    'Kenia': 'KE',
    'Kirguistán': 'KG',
    'Kiribati': 'KI',
    'Kuwait': 'KW',
    'Líbano': 'LB',
    'Laos': 'LA',
    'Lesoto': 'LS',
    'Letonia': 'LV',
    'Liberia': 'LR',
    'Libia': 'LY',
    'Liechtenstein': 'LI',
    'Lituania': 'LT',
    'Luxemburgo': 'LU',
    'México': 'MX',
    'Mónaco': 'MC',
    'Macao': 'MO',
    'Macedônia': 'MK',
    'Madagascar': 'MG',
    'Malasia': 'MY',
    'Malawi': 'MW',
    'Mali': 'ML',
    'Malta': 'MT',
    'Marruecos': 'MA',
    'Martinica': 'MQ',
    'Mauricio': 'MU',
    'Mauritania': 'MR',
    'Mayotte': 'YT',
    'Micronesia': 'FM',
    'Moldavia': 'MD',
    'Mongolia': 'MN',
    'Montenegro': 'ME',
    'Montserrat': 'MS',
    'Mozambique': 'MZ',
    'Namibia': 'NA',
    'Nauru': 'NR',
    'Nepal': 'NP',
    'Nicaragua': 'NI',
    'Niger': 'NE',
    'Nigeria': 'NG',
    'Niue': 'NU',
    'Noruega': 'NO',
    'Nueva Caledonia': 'NC',
    'Nueva Zelanda': 'NZ',
    'Omán': 'OM',
    'Países Bajos': 'NL',
    'Pakistán': 'PK',
    'Palau': 'PW',
    'Palestina': 'PS',
    'Panamá': 'PA',
    'Papúa Nueva Guinea': 'PG',
    'Paraguay': 'PY',
    'Perú': 'PE',
    'Polinesia Francesa': 'PF',
    'Polonia': 'PL',
    'Portugal': 'PT',
    'Puerto Rico': 'PR',
    'Qatar': 'QA',
    'Reino Unido': 'GB',
    'República Centroafricana': 'CF',
    'República Checa': 'CZ',
    'República Dominicana': 'DO',
    'República de Sudán del Sur': 'SS',
    'Reunión': 'RE',
    'Ruanda': 'RW',
    'Rumanía': 'RO',
    'Rusia': 'RU',
    'Sahara Occidental': 'EH',
    'Samoa': 'WS',
    'Samoa Americana': 'AS',
    'San Bartolomé': 'BL',
    'San Cristóbal y Nieves': 'KN',
    'San Marino': 'SM',
    'San Martín (Francia)': 'MF',
    'San Pedro y Miquelón': 'PM',
    'San Vicente y las Granadinas': 'VC',
    'Santa Elena': 'SH',
    'Santa Lucía': 'LC',
    'Santo Tomé y Príncipe': 'ST',
    'Senegal': 'SN',
    'Serbia': 'RS',
    'Seychelles': 'SC',
    'Sierra Leona': 'SL',
    'Singapur': 'SG',
    'Sint Maarten': 'SX',
    'Siria': 'SY',
    'Somalia': 'SO',
    'Sri lanka': 'LK',
    'Sudáfrica': 'ZA',
    'Sudán': 'SD',
    'Suecia': 'SE',
    'Suiza': 'CH',
    'Surinám': 'SR',
    'Svalbard y Jan Mayen': 'SJ',
    'Swazilandia': 'SZ',
    'Tayikistán': 'TJ',
    'Tailandia': 'TH',
    'Taiwán': 'TW',
    'Tanzania': 'TZ',
    'Territorio Británico del Océano Índico': 'IO',
    'Territorios Australes y Antárticas Franceses': 'TF',
    'Timor Oriental': 'TL',
    'Togo': 'TG',
    'Tokelau': 'TK',
    'Tonga': 'TO',
    'Trinidad y Tobago': 'TT',
    'Tunez': 'TN',
    'Turkmenistán': 'TM',
    'Turquía': 'TR',
    'Tuvalu': 'TV',
    'Ucrania': 'UA',
    'Uganda': 'UG',
    'Uruguay': 'UY',
    'Uzbekistán': 'UZ',
    'Vanuatu': 'VU',
    'Venezuela': 'VE',
    'Vietnam': 'VN',
    'Wallis y Futuna': 'WF',
    'Yemen': 'YE',
    'Yibuti': 'DJ',
    'Zambia': 'ZM',
    'Zimbabue': 'ZW',
  };
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <DataContextConsumer>
      {context => (
        <React.Fragment>
          <DropdownFixedNavbar />
          <div className="wrapper about-us-styles">
            <AboutUsHeader image={require("assets/img/banners/7.png")} title="Equipo Paradiplomacia" subtitle="Primero las personas" />
            <div className="section pb-0" id="equipo">
              <div className="about-team team-4 pb-0 pt-0">
                <Container>
                  <Row>
                    <Col className="" md="12">
                      <h2 className="title pt-0">¿Qué es Paradiplomacia.org?</h2>
                      <p className="description">
                        Somos una organización sin fines de lucro que busca ser protagonista en la transformación de las ciudades actuales a ciudades más globales, humanas e inteligentes.
                      </p>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            {/* <div className="section mt-0 pb-0" id="vision">
              <div className="vision-styles">
                <Container>
                  <Row>
                    <Col className="mt-auto mb-auto" md="8">
                      <h2 className="title" >Visión</h2>
                      <p className="description">
                        Trabajamos para estudiar, acompañar y promover la proyección internacional de las ciudades y su desarrollo inteligente, humano y sustentable.
                      </p>
                    </Col>
                    <Col md="4" className="ml-auto mr-auto mt-5">
                      <img
                        alt="..."
                        className="img px-5"
                        src={require("assets/img/2.png")}
                      ></img>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div> */}
            <div className="section pt-3 mt-5" id="mision">
              <div className="mision-styles">
                <Container>
                  <Row>
                    <Col md="4" className="ml-auto mr-auto mt-5">
                      <img
                        alt="..."
                        className="img"
                        src={require("assets/img/3.png")}
                      ></img>
                    </Col>
                    <Col className="mt-auto mb-auto" md="8">
                      <h2 className="title" >Visión</h2>
                      <p className="description">
                        Ser referentes en innovación para los gobiernos locales, inteligentes y humanos partiendo de la cooperación internacional y las soluciones smart en un mundo moderno.
                      </p>
                      <hr></hr>
                      <h2 className="title">Misión</h2>
                      <p className="description">
                        Estudiamos, acompañamos y promovemos la proyección internacional de los gobiernos locales  y su desarrollo inteligente, humano y sustentable. Proponemos día a día compartir experiencias exitosas que sirvan de enseñanza e inspiración para nuevos proyectos locales.
                      </p>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <div className="about-team team-4 pb-5 pt-0 mb-5">
              <Container>
                <Row>
                  <Col md="12">
                    <h2 className="title">Equipo</h2>
                  </Col>
                  {
                    context.data.equipo ?
                      (context.data.equipo).map((item, i) =>
                        <Col className="ml-auto mr-auto" md="6">
                          <Card className="card-profile card-plain mt-2">
                            <Row>
                              <Col className="offset-sm-2 offset-md-0" sm="4">
                                <div className="card-image">
                                  <div
                                    className="img rounded-circle img-raised profile-photo"
                                    style={{
                                      backgroundImage:
                                        "url(" + Functions.getDriveImages(item.Foto) + ")"
                                    }}
                                  ></div>
                                  {/* <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img rounded-circle img-raised profile-photo"
                                      src={Functions.getDriveImages(item.Foto)}
                                    ></img>
                                  </a> */}
                                </div>
                              </Col>
                              <Col sm="6" md="8">
                                <CardBody>
                                  <CardTitle tag="h4">{item.Nombre}</CardTitle>
                                  <h5 className="category">{item.Rol}</h5>
                                  <div className="country">
                                    {
                                      item.País && staticFlags[item.País] &&
                                      <img
                                        alt="..."
                                        className="img px-2"
                                        src={require(`assets/img/flags/${staticFlags[item.País]}.png`)}
                                      ></img>
                                    }
                                    <p className="card-description">
                                      {item.País}
                                    </p>
                                  </div>
                                  <CardFooter className="text-left">
                                    {
                                      item.Twitter &&
                                      <Link to={item.Twitter} rel="noopener noreferrer" target="_blank">
                                        <Button
                                          className="btn-icon btn-round btn btn-info"
                                          color="twitter"
                                          href={item.Twitter}
                                          target="_blank"
                                        >
                                          <i className="fab fa-twitter"></i>
                                        </Button>
                                      </Link>
                                    }
                                    {
                                      item.Email &&
                                      <Button
                                        className="btn-icon btn-round btn btn-info"
                                        color="twitter"
                                        href={`mailto:${item.Email}`}
                                        target="_blank"
                                      >
                                        <i className="fab fas fa-envelope"></i>
                                      </Button>
                                    }
                                    {
                                      item.Linkedin &&
                                      <a href={item.Linkedin} rel="noopener noreferrer" target="_blank">
                                        <Button
                                          className="btn-icon btn-round btn btn-info"
                                          color="linkedin"

                                          target="_blank"
                                        >
                                          <i className="fab fa-linkedin"></i>
                                        </Button>
                                      </a>
                                    }
                                    {
                                      item.Instagram &&
                                      <a href={item.Instagram} rel="noopener noreferrer" target="_blank">
                                        <Button
                                          className="btn-icon btn-round btn btn-info"
                                          color="instagram"

                                          target="_blank"
                                        >
                                          <i className="fab fa-instagram"></i>
                                        </Button>
                                      </a>
                                    }
                                  </CardFooter>
                                </CardBody>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      ) :
                      <Col className="ml-auto mr-auto" md="8">
                        <div className="section-description text-center">
                          <h4>No se encontraron miembros</h4>
                        </div>
                      </Col>
                  }
                  <Col className="ml-auto mr-auto mt-4" md="12">
                    <div className="section-description text-center">
                      <Link to="/que-hacemos">
                        <Button size="lg" className="btn-round mt-0 mb-0" color="primary" outline>
                          Ver también: ¿Qué hacemos?
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <Footer />
            {/* <Dots customLoading={context.loading} color={'#FFFFFF'} /> */}
          </div>
        </React.Fragment>
      )}
    </DataContextConsumer>
  );
}

export default AboutUs;
