import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Container,
  Button,
  Row,
  Col
} from "reactstrap";

// core components
import DropdownFixedNavbar from "pincelit/components/Navbar.js";
import AboutUsHeader from "pincelit/components/PageHeader.js";
import Footer from "pincelit/components/Footer.js";

function AboutUs() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <DropdownFixedNavbar />
      <div className="wrapper about-us-styles">
        <AboutUsHeader
          image={require("assets/img/banners/1.png")}
          title="Aprender, cooperar, inspirar"
          subtitle="Para tener ciudades globales, más humanas e inteligentes."
        />
        <div className="section pb-0">
          <div className="vision-styles">
            <Container>
              <Row>
                <Col className="" md="12">
                  <h2 className="title pt-0">¿Qué hacemos?</h2>
                  {/* <p className="description">
                    <strong>Brindamos herramientas para transformar la realidad</strong> de los gobiernos locales que buscan, como función principal, mejorar la vida de sus vecinos.<br /><br />
                    <strong>Creemos en la cooperación internacional</strong> como motor de desarrollo, intercambio y actualización permanente para todos los gobiernos, no importa su tamaño, sus recursos y su contexto.<br /><br />
                    Promovemos el <strong>desarrollo inteligente de las ciudades</strong> para hacerlas más humanas, eficientes y conectadas.<br /><br />
                    Estudiamos el surgimiento de <strong>nuevas formas de gestionar</strong> una ciudad de manera inteligente, global y humana.<br /><br />
                    <strong>Abrazamos los principios</strong> de transparencia, inclusión, diversidad, para un desarrollo humano, eficiente y cooperativo de los gobiernos locales.
                  </p> */}
                </Col>
                <Col md="4">
                  <div className="info info-hover">
                    <div className="icon icon-info icon-circle">
                      <i class="fas fa-tools"></i>
                    </div>
                    <h4 className="info-title">Brindamos herramientas</h4>
                    <p className="description">
                      para los gobiernos locales que buscan, como función
                      principal, mejorar la vida de sus vecinos.
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="info info-hover">
                    <div className="icon icon-info icon-circle">
                      <i class="fas fa-handshake"></i>
                    </div>
                    <h4 className="info-title">
                      Creemos en la cooperación internacional
                    </h4>
                    <p className="description">
                      como motor de desarrollo, intercambio y actualización
                      permanente para todos los gobiernos, no importa su tamaño,
                      sus recursos y su contexto.
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="info info-hover">
                    <div className="icon icon-info icon-circle">
                      <i class="fas fa-city"></i>
                    </div>
                    <h4 className="info-title">
                      Promovemos el desarrollo inteligente de las ciudades
                    </h4>
                    <p className="description">
                      para hacerlas más humanas, eficientes y conectadas.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="offset-sm-0 offset-md-2" md="4">
                  <div className="info info-hover">
                    <div className="icon icon-info icon-circle">
                      <i class="fas fa-book"></i>
                    </div>
                    <h4 className="info-title">Estudiamos</h4>
                    <p className="description">
                      el surgimiento de{" "}
                      <strong>nuevas formas de gestionar</strong> un gobierno
                      local de manera global, inteligente y humana.
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="info info-hover">
                    <div className="icon icon-info icon-circle">
                      <i class="fas fa-users"></i>
                    </div>
                    <h4 className="info-title">Abrazamos los principios</h4>
                    <p className="description">
                      de transparencia, democracia, inclusión y diversidad, para
                      un desarrollo humano, eficiente y cooperativo de los
                      gobiernos locales.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="section pt-0 pb-5 mb-5" id="colabore">
          <div className="vision-styles">
            <Container>
              <Row>
                <Col className="mt-auto mb-auto" md="8">
                  <h2 className="title">Colabore con nosotros</h2>
                  <p className="description">
                    Paradiplomacia.org está abierto a todo tipo de ayuda para el
                    logro de su fin, por lo que todos los aportes son
                    bienvenidos. Para tu tranquilidad, la Asociación está
                    debidamente registrada y controlada por la IGJ y utiliza una
                    de las plataformas de donación más confiables de
                    latinoamérica para asegurar la transparencia de sus
                    ingresos.
                  </p>
                  <Row>
                    <Col md="4">
                      <a href="https://mpago.la/1P1tsLD" target="_blank">
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/meli.png")}
                        ></img>
                      </a>
                    </Col>
                    <Col md="4">
                      <a
                        href="https://donaronline.org/paradiplomacia-org/aporte-voluntario-1"
                        target="_blank"
                      >
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/donar.png")}
                        ></img>
                      </a>
                    </Col>
                  </Row>
                </Col>
                <Col md="4" className="ml-auto mr-auto mt-5">
                  <img
                    alt="..."
                    className="img"
                    src={require("assets/img/4.png")}
                  ></img>
                </Col>
                <Col className="ml-auto mr-auto mt-4" md="12">
                  <div className="section-description text-center">
                    <Link to="/quienes-somos">
                      <Button
                        size="lg"
                        className="btn-round mt-0 mb-0"
                        color="primary"
                        outline
                      >
                        Ver también: ¿Quiénes somos?
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {/* <div className="section pt-0 mb-5 pb-5" id="trabajeConNosotros">
          <div className="mision-styles">
            <Container>
              <Row>
                <Col md="4" className="ml-auto mr-auto mt-5">
                  <img
                    alt="..."
                    className="img"
                    src={require("assets/img/5.png")}
                  ></img>
                </Col>
                <Col className="mt-auto mb-auto" md="8">
                  <h2 className="title">Trabaje con nosotros</h2>
                  <p className="description">
                    Estamos interesados en conformar un equipo de trabajo multidisciplinario, integrado por representantes de los diversos gobiernos locales de Latinoamérica y el mundo.<br /><br />

                    Si estas de acuerdo con nuestros valores y tenés ganas de participar en la organización, te invitamos a sumarte a Paradiplomacia.org. Para ello, necesitamos que nos envíes un correo electrónico a paradiplomacia@paradiplomacia.org. con un breve CV, y descripción de los motivos de por qué querés ser parte de nuestra institución.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div> */}
        <Footer />
      </div>
    </>
  );
}

export default AboutUs;
