import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dots } from 'react-preloaders';

// import { GoogleSpreadsheet } from 'google-spreadsheet';

// Reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Badge,
  Container,
  Row,
  Col
} from "reactstrap";

// Components
import FixedTransparentNavbar from "pincelit/components/Navbar.js";
import BlogPostsHeader from "pincelit/components/PageHeader.js";
import Footer from "pincelit/components/Footer.js";

// Context
import { DataContextConsumer } from "services/dataContext.js";

// Utils
import Functions from 'pincelit/utils/functions.js';

function Tools() {
  // hardcode values
  const toolsSize = 12;
  const categoryPath = decodeURI(window.location.pathname.split("/").pop());
  const staticInfo = {
    herramientas: {
      title: 'Herramientas',
      subtitle: 'Oferta para el aprendizaje.',
      breadcrumb: [],
      description: 'Esta sección busca compartir la oferta de cursos y material didáctico existente para la formación de gestores y académicos en el universo de los gobiernos locales inteligentes y globales.'
    },
    capacitacionesglobalcities: {
      title: 'Global Cities Capacitaciones',
      breadcrumb: [{ name: 'Herramientas', url: '/herramientas' }],
      subtitle: 'Aprendizaje y discusión para gestiones globales.',
      description: 'Este espacio se destina a promover toda la oferta de capacitaciones relacionada a nuestro objeto de estudio para todo funcionario local e investigador de las relaciones internacionales.'
    },
    capacitacionessmartcities: {
      title: 'Smart Cities Capacitaciones',
      breadcrumb: [{ name: 'Herramientas', url: '/herramientas' }],
      subtitle: 'Aprendizaje y discusión para gestiones inteligentes.',
      description: 'Conocimiento enfocado a gestores locales para diseñar y fortalecer una ciudad inteligente focalizada en las personas.'
    },
  };
  const [toolsDisplay, setToolsDisplay] = useState(toolsSize - 1);
  const getFilteredToolsData = (data) => {
    let filteredData = [];
    data && data.map(item =>
      Functions.checkResultsFromPath(item.Categoría, 'herramientas')
      &&
      filteredData.push(item)
    )
    return filteredData;
  }
  React.useEffect(() => {
    // updateTools()
    document.body.classList.add("blog-posts");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("blog-posts");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <DataContextConsumer>
      {context => (
        <React.Fragment>
          <FixedTransparentNavbar />
          <div className="wrapper">
            <BlogPostsHeader breadcrumbItems={staticInfo[categoryPath.replace(/\s/g, '')].breadcrumb} image={require("assets/img/default—banner@2x.png")} title={staticInfo[categoryPath.replace(/\s/g, '')].title} subtitle={staticInfo[categoryPath.replace(/\s/g, '')].subtitle} />
            <div className="section tools-custom-styles">
              <Container>
                <Row>
                  <Col md="12" className="mb-5">
                    <p className="section-description">
                      {staticInfo[categoryPath.replace(/\s/g, '')].description}
                    </p>
                  </Col>
                </Row>
                <Row>
                  {
                    context.data.herramientas ?
                      ((getFilteredToolsData(context.data.herramientas))).map((item, i) =>
                      i <= (toolsDisplay) &&
                      <Col data-js={item.Id} key={item.Id} sm="12" md="6" lg="4">
                        <Card className="card-plain card-blog">
                          <div className="card-image">
                            <Link to={`/herramienta/${Functions.getNumbers(item["Marca temporal"])}`}>
                              <div
                                className="img rounded-circle img-raised tool-img"
                                style={{
                                  backgroundImage:
                                    "url(" + Functions.getDriveImages(item.Imagen) + ")"
                                }}
                              ></div>
                            </Link>
                          </div>
                          <CardBody>
                            <CardTitle className="text-center">
                              <Link to={`/herramienta/${Functions.getNumbers(item["Marca temporal"])}`}>
                                <Badge color="primary mb-1">{item.Categoría}</Badge>
                                <h4 className="pt-1 mt-0">{item.Título}</h4>
                                <p>{item.Subtítulo}</p>
                              </Link>
                            </CardTitle>
                            <CardFooter className="text-center">
                              <Link to={`/herramienta/${Functions.getNumbers(item["Marca temporal"])}`}>
                                <Button
                                  size="sm"
                                  className="btn-round mt-0 mb-0"
                                  color="primary"
                                  outline
                                >
                                  Leer más
                              </Button>
                              </Link>
                            </CardFooter>
                          </CardBody>
                        </Card>
                      </Col>
                    ) :
                      <Col className="ml-auto mr-auto" md="8">
                        <div className="section-description text-center">
                          <h4>No se encontraron herramientas</h4>
                        </div>
                      </Col>
                  }
                </Row>
                {
                  (toolsDisplay + 1) < (getFilteredToolsData(context.data.herramientas)).length &&
                  <Row>
                    <Col className="ml-auto mr-auto" md="8">
                      <div className="section-description text-center">
                        <Button
                          className="btn-round"
                          color="info"
                          onClick={() => { setToolsDisplay(toolsDisplay + toolsSize) }}
                        >
                          Ver más
                  </Button>
                      </div>
                    </Col>
                  </Row>
                }
              </Container>
            </div>
            <Footer />
            {/* <Dots customLoading={context.loading} color={'#FFFFFF'} /> */}
          </div>
        </React.Fragment>
      )}
    </DataContextConsumer>
  );
}

export default Tools;
