import React, { Component } from "react";
import { GoogleSpreadsheet } from 'google-spreadsheet';
const { Provider, Consumer } = React.createContext();

class DataContextProvider extends Component {
    state = {
        data: {
            novedades: '',
            revistas: '',
            libros: '',
            trabajos: '',
            partners: '',
            equipo: '',
            multilateral: '',
            herramientas: '',
        },
        loading: true,
        loaded: false,
        doc:'',
        docLoaded: false,
        partialLoaded: false,
    };

    async componentDidMount() {
        await this.getDoc()
        await this.getCurrentPathData()
        this.getData()
    }

    getDoc = async () => {
        if (!this.state.docLoaded) {
            const creds = require('credentials.json');
            const doc = new GoogleSpreadsheet('1mUhUCwdD5hUrVaTLQ7nDEeounxlqG5Ggu5R9LgqAsOg');
            await doc.useServiceAccountAuth(creds);
            await doc.loadInfo();
            this.setState(() => {
                return {
                    doc,
                    docLoaded: true,
                };
            });
        }
    };


    getCurrentPathData = async () => {
        if (!this.state.loaded && !this.state.partialLoaded) {
            const location = (decodeURI(window.location.pathname)).split("/")[1];
            const newsSheet = this.state.doc.sheetsByIndex[0];
            const magazineSheet = this.state.doc.sheetsByIndex[1];
            const booksSheet = this.state.doc.sheetsByIndex[2];
            const partnersSheet = this.state.doc.sheetsByIndex[3];
            const teamSheet = this.state.doc.sheetsByIndex[4];
            const multilateralSheet = this.state.doc.sheetsByIndex[5];
            const toolsSheet = this.state.doc.sheetsByIndex[6];
            const academicWorksSheet = this.state.doc.sheetsByIndex[7];
            let newsRows = this.state.data.novedades;
            let magazineRows = this.state.data.revistas;
            let booksRows = this.state.data.libros;
            let partnersRows = this.state.data.partners;
            let teamRows = this.state.data.equipo;
            let multilateralRows = this.state.data.multilateral;
            let toolsRows = this.state.data.herramientas;
            let academicWorksRows = this.state.data.trabajos;

            switch (location) {
                case 'inicio':
                    newsRows = await newsSheet.getRows();
                    magazineRows = await magazineSheet.getRows();
                    this.setState(() => {
                        return {
                            data: {
                                ...this.state.data,
                                novedades: newsRows.reverse(),
                                revistas: magazineRows.reverse(),
                            },
                        };
                    });
                    break;
                case 'novedades':
                    newsRows = await newsSheet.getRows();
                    this.setState(() => {
                        return {
                            data: {
                                ...this.state.data,
                                novedades: newsRows.reverse(),
                            },
                        };
                    });
                    break;
                case 'partners':
                    partnersRows = await partnersSheet.getRows();
                    this.setState(() => {
                        return {
                            data: {
                                ...this.state.data,
                                partners: partnersRows,
                            },
                        };
                    });
                    break;
                case 'multilateral':
                    multilateralRows = await multilateralSheet.getRows();
                    this.setState(() => {
                        return {
                            data: {
                                ...this.state.data,
                                multilateral: multilateralRows,
                            },
                        };
                    });
                    break;
                case 'revista-tip':
                    magazineRows = await magazineSheet.getRows();
                    this.setState(() => {
                        return {
                            data: {
                                ...this.state.data,
                                revistas: magazineRows.reverse(),
                            },
                        };
                    });
                    break;
                case 'artículo':
                    newsRows = await newsSheet.getRows();
                    this.setState(() => {
                        return {
                            data: {
                                ...this.state.data,
                                novedades: newsRows,
                            },
                        };
                    });
                    break;
                case 'libro':
                    booksRows = await booksSheet.getRows();
                    this.setState(() => {
                        return {
                            data: {
                                ...this.state.data,
                                libros: booksRows.reverse(),
                            },
                        };
                    });
                    break;
                case 'libros':
                    booksRows = await booksSheet.getRows();
                    this.setState(() => {
                        return {
                            data: {
                                ...this.state.data,
                                libros: booksRows.reverse(),
                            },
                        };
                    });
                    break;
                case 'quienes-somos':
                    teamRows = await teamSheet.getRows();
                    this.setState(() => {
                        return {
                            data: {
                                ...this.state.data,
                                equipo: teamRows,
                            },
                        };
                    });
                    break;
                case 'revista':
                    magazineRows = await magazineSheet.getRows();
                    this.setState(() => {
                        return {
                            data: {
                                ...this.state.data,
                                revistas: magazineRows.reverse(),
                            },
                        };
                    });
                    break;
                case 'herramientas':
                    toolsRows = await toolsSheet.getRows();
                    this.setState(() => {
                        return {
                            data: {
                                ...this.state.data,
                                herramientas: toolsRows,
                            },
                        };
                    });
                    break;
                case 'herramienta':
                    toolsRows = await toolsSheet.getRows();
                    this.setState(() => {
                        return {
                            data: {
                                ...this.state.data,
                                herramientas: toolsRows,
                            },
                        };
                    });
                    break;
                case 'trabajos-academicos':
                    academicWorksRows = await academicWorksSheet.getRows();
                    this.setState(() => {
                        return {
                            data: {
                                ...this.state.data,
                                trabajos: academicWorksRows,
                            },
                        };
                    });
                    break;
                case 'trabajo-academico':
                    academicWorksRows = await academicWorksSheet.getRows();
                    this.setState(() => {
                        return {
                            data: {
                                ...this.state.data,
                                trabajos: academicWorksRows,
                            },
                        };
                    });
                    break;
                default:
                    newsRows = await newsSheet.getRows();
                    magazineRows = await magazineSheet.getRows();
                    booksRows = await booksSheet.getRows();
                    partnersRows = await partnersSheet.getRows();
                    teamRows = await teamSheet.getRows();
                    multilateralRows = await multilateralSheet.getRows();
                    toolsRows = await toolsSheet.getRows();
                    academicWorksRows = await academicWorksSheet.getRows();
                    this.setState(() => {
                        return {
                            data: {
                                novedades: newsRows.reverse(),
                                revistas: magazineRows.reverse(),
                                libros: booksRows.reverse(),
                                partners: partnersRows,
                                equipo: teamRows,
                                multilateral: multilateralRows,
                                herramientas: toolsRows,
                                trabajos: academicWorksRows,
                            },
                            partialLoaded: true,
                        };
                    });
                break;
            }
            this.setState(() => {
                return {
                    loading: false,
                    partialLoaded: true,
                };
            });
        }
    };

    getData = async() => {
        if (!this.state.loaded){
            // Variables
            const newsSheet = this.state.doc.sheetsByIndex[0];
            const magazineSheet = this.state.doc.sheetsByIndex[1];
            const booksSheet = this.state.doc.sheetsByIndex[2];
            const partnersSheet = this.state.doc.sheetsByIndex[3];
            const teamSheet = this.state.doc.sheetsByIndex[4];
            const multilateralSheet = this.state.doc.sheetsByIndex[5];
            const toolsSheet = this.state.doc.sheetsByIndex[6];
            const academicWorksSheet = this.state.doc.sheetsByIndex[7];
            let newsRows = this.state.data.novedades;
            let magazineRows = this.state.data.revistas;
            let booksRows = this.state.data.libros;
            let partnersRows = this.state.data.partners;
            let teamRows = this.state.data.equipo;
            let multilateralRows = this.state.data.multilateral;
            let toolsRows = this.state.data.herramientas;
            let academicWorksRows = this.state.data.trabajos;
            newsRows = await newsSheet.getRows();
            magazineRows = await magazineSheet.getRows();
            booksRows = await booksSheet.getRows();
            partnersRows = !partnersRows ? await partnersSheet.getRows(): partnersRows;
            teamRows = !teamRows ? await teamSheet.getRows(): teamRows;
            multilateralRows = !multilateralRows ? await multilateralSheet.getRows(): multilateralRows;
            toolsRows = !toolsRows ? await toolsSheet.getRows(): toolsRows;
            academicWorksRows = !academicWorksRows ? await academicWorksSheet.getRows(): academicWorksRows;
            this.setState(() => {
                return {
                    data: {
                        novedades: newsRows.reverse(),
                        revistas: magazineRows.reverse(),
                        libros: booksRows.reverse(),
                        partners: partnersRows,
                        equipo: teamRows,
                        multilateral: multilateralRows,
                        herramientas: toolsRows,
                        trabajos: academicWorksRows,
                    },
                    loaded: true,
                    loading: false,
                };
            });
        }
    };

    render() {
        return (
            <Provider
                value={{ data: this.state.data, loading: this.state.loading, loaded: this.state.loaded, getData: this.getData }}
            >
                {this.props.children}
            </Provider>
        );
    }
}

export { DataContextProvider, Consumer as DataContextConsumer };
