import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReadingTime from 'reading-time';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import FixedTransparentNavbar from "pincelit/components/Navbar.js";
import PageHeader from "pincelit/components/PageHeader.js";
import Footer from "pincelit/components/Footer.js";

// Context
import { DataContextConsumer } from "services/dataContext.js";

// Utils
import Functions from 'pincelit/utils/functions.js';

function News() {
  // hardcode values
  const newsSize = 6;

  // states
  const [newsDisplay, setNewsDisplay] = useState(newsSize - 1);
  const category = decodeURI(window.location.pathname.split("/").pop());
  const staticInfo = {
    novedades: {
      title:'Novedades',
      subtitle: 'Las gobiernos locales en la vanguardia del cambio.',
      breadcrumb: [],
      description:'Espacio colaborativo donde compartimos información actual y abierta acerca del desarrollo de las ciudades inteligentes y globales.'
    },
    experienciasglobalcities: {
      title: 'Experiencias Globales',
      breadcrumb: [{ name: 'Novedades', url: '/novedades' }],
      subtitle: 'Todos los gobiernos locales podemos cooperar.',
      description: 'Las ciudades globales pueden ser pueblos pequeños, grandes metrópolis, ciudades intermedias o grandes puertos, lo que las une es la creatividad para posicionarse internacionalmente, veamos las buenas prácticas!'
    },
    globalcity: {
      title: 'Novedades Gobiernos Globales',
      breadcrumb: [{ name: 'Novedades', url: '/novedades' }],
      subtitle: 'La paradiplomacia en los gobiernos locales.',
      description: 'Los gobiernos locales que se conectan con el mundo y re-inventan la paradiplomacia día a día, en esta sección buscamos ir detrás de esas tendencias y actualizarnos.'
    },
    smartcity: {
      title: 'Smart City',
      breadcrumb: [{ name: 'Novedades', url: '/novedades' }],
      subtitle: '',
      description: ''
    },
    tecnología: {
      title: 'Tecnología',
      breadcrumb: [{ name: 'Novedades', url: '/novedades' }],
      subtitle: 'Herramientas para gobiernos locales.',
      description: 'La tecnología permite medir la gestión, conectar a los ciudadanos, cuidarlos, moverlos, sorprenderlos, en todo lugar y en todo momento. La sociedad del conocimiento al alcance local.'
    },
    experienciassmartcities: {
      title: 'Experiencias Smart Cities',
      breadcrumb: [{ name: 'Novedades', url: '/novedades' }],
      subtitle: 'Iniciativas innovadoras para problemáticas comunes.',
      description: 'Ideas originales para retos frecuentes en una ciudad inteligente focalizada en las personas.'
    },
  };
  const getFilteredNewsData = (data) => {
    let filteredData = [];
    data && data.map(item =>
      Functions.checkResultsFromPath(item.Categoría, 'novedades')
      &&
      filteredData.push(item)
    )
    return filteredData;
  }
  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("blog-posts");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <DataContextConsumer>
      {context => (
        <React.Fragment>
          <FixedTransparentNavbar />
          <div className="wrapper">
            <PageHeader breadcrumbItems={staticInfo[category.replace(/\s/g, '')].breadcrumb} image={require("assets/img/banners/8.png")} title={staticInfo[category.replace(/\s/g, '')].title} subtitle={staticInfo[category.replace(/\s/g, '')].subtitle} />
            <div className="section section-about-us">
              <Container>
                <Row>
                  <Col md="12" className="mb-5">
                    <p className="section-description">
                      {staticInfo[category.replace(/\s/g, '')].description}
                    </p>
                  </Col>
                </Row>
                <Row>
                  {
                    context.data.novedades ?
                      ((getFilteredNewsData(context.data.novedades))).map((item, i) =>
                        i <= (newsDisplay) &&
                        <Col data-js={item.Id} key={item.Id} sm="12" md="6" lg="4">
                          <Card className="card-plain card-blog">
                            <div className="card-image">
                              <Link to={`/artículo/${Functions.getNumbers(item["Marca temporal"])}`}>
                                <div
                                  className="img rounded img-raised article-wrapper"
                                >
                                  <div
                                    className="article-cover"
                                    style={{
                                      backgroundImage:
                                        "url(" + Functions.getDriveImages(item.Portada) + ")"
                                    }}
                                  ></div>
                                  <div
                                    className="article-img"
                                    style={{
                                      backgroundImage:
                                        "url(" + Functions.getDriveImages(item.Portada) + ")"
                                    }}
                                  >

                                  </div>
                                </div>
                              </Link>
                            </div>
                            <CardBody>
                              <Badge color="primary" className="mr-2">{item.Categoría}</Badge>
                              {
                                item.Tags &&
                                (item.Tags).split(',').map(tag => (
                                  <Link to={`/busqueda/${tag}`}>
                                    <Badge color="info" className="mr-2 growth">{tag}</Badge>
                                  </Link>
                                ))
                              }
                              <p className="reading-time-text">{item.Descripción && `${Math.ceil(ReadingTime(item.Descripción).minutes)} min de lectura`}</p>
                              <CardTitle tag="h4">
                                <Link to={`/artículo/${Functions.getNumbers(item["Marca temporal"])}`}>
                                  {item.Título}
                                </Link>
                              </CardTitle>
                              <Link to={`/artículo/${Functions.getNumbers(item["Marca temporal"])}`}>
                                <Button
                                  size="sm"
                                  className="btn-round mt-0 mb-0"
                                  color="primary"
                                  outline
                                >
                                  Leer más
                            </Button>
                              </Link>
                            </CardBody>
                          </Card>
                        </Col>
                    ) :
                      <Col className="ml-auto mr-auto" md="8">
                        <div className="section-description text-center">
                          <h4>No se encontraron novedades</h4>
                        </div>
                      </Col>
                  }
                </Row>
                {
                  (newsDisplay + 1) < ((getFilteredNewsData(context.data.novedades))).length &&
                  <Row>
                    <Col className="ml-auto mr-auto" md="8">
                      <div className="section-description text-center">
                        <Button
                          className="btn-round"
                          color="info"
                          onClick={() => { setNewsDisplay(newsDisplay + newsSize) }}
                        >
                          Ver más
                        </Button>
                      </div>
                    </Col>
                  </Row>
                }
              </Container>
            </div>
            <Footer />
            {/* <Dots customLoading={context.loading} color={'#FFFFFF'} /> */}
          </div>
        </React.Fragment>
      )}
    </DataContextConsumer>
  );
}

export default News;
