import React, { useState } from "react";
import { GoogleSpreadsheet } from 'google-spreadsheet';

// reactstrap components
import {
  Button,
  Container,
  Row,
  Card,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Col
} from "reactstrap";

// core components

function FooterSocial() {
  const [emailFocus, setEmailFocus] = useState(null);
  const [newsletterState, setNewsletterState] = useState(null);
  const newsletterSubmit = async(e) => {
    e.preventDefault();
    const email = e.target.elements.email.value;
    setNewsletterState('loading')
    const creds = require('credentials.json');
    const doc = new GoogleSpreadsheet('1mUhUCwdD5hUrVaTLQ7nDEeounxlqG5Ggu5R9LgqAsOg');
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo();
    const emailsSheet = doc.sheetsByIndex[8];
    await emailsSheet.addRow({ emails: email });
    setNewsletterState('complete')
  };
  return (
    <>
      <div className="subscribe-line">
        <Container>
          <Row>
            <Col md="6" className="content-styles">
              <h4 className="title">Suscríbete al newsletter</h4>
              <p className="description">
                Recibe todas las noticias desde tu casilla de email.
              </p>
            </Col>
            <Col md="6" className="mt-auto mb-auto">
              <Card className="card-plain card-form-horizontal my-0">
                <div className="card-content">
                  <Form className="mt-auto mb-auto" onSubmit={newsletterSubmit}>
                    <Row>
                      <Col sm="8">
                        <InputGroup
                          className={emailFocus ? "input-group-focus mb-0" : "mb-0"}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_email-85"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Ingrese su email"
                            type="email"
                            className="newsletter-styles-custom"
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                            name="email"
                          ></Input>
                        </InputGroup>
                      </Col>
                      <Col sm="4">
                        <Button
                          block
                          className="btn-round"
                          color={(() => {
                            switch (newsletterState) {
                              case "complete": return "success";
                              case "loading": return "info";
                              default: return "info";
                            }
                          })()}
                          type="submit"
                        >
                          {(() => {
                            switch (newsletterState) {
                              case "complete": return "Listo!";
                              case "loading": return "Cargando";
                              default: return "Suscribirse";
                            }
                          })()}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <footer
        className="footer"
        style={{
          backgroundImage:
            "url(" + require("assets/img/bg4.png") + ")"
        }}
      >
        <Container>
          <div className="content">
            <Row>
              <Col md="12">
                <Row>
                  <Col md="6" className="ml-auto mr-auto p-block">
                    <Row className="jc-center ai-center nowrap">
                      <img
                        alt="..."
                        className="img tip-logo mt-auto mb-auto px-2 mr-4"
                        src={require("assets/img/logomin.png")}
                      ></img>
                      <div className="mt-auto mb-auto">
                        <p className="my-2"><i className="fab fas fa-envelope mr-2"></i> <a href="mailto:info@paradiplomacia.org">info@paradiplomacia.org</a></p>
                        <p className="my-2">Asociación Civil Paradiplomacia.org</p>
                        <p className="my-2">Resolución I.G.J. N 2532/17</p>
                      </div>
                    </Row>
                  </Col>
                  <Col md="6" className="ml-auto mr-auto tip-block">
                    <Row className="jc-center ai-center nowrap">
                      <img
                        alt="..."
                        className="img tip-logo mt-auto mb-auto px-2 mr-4"
                        src={require("assets/img/tip.png")}
                      ></img>
                      <div className="mt-auto mb-auto">
                        <p className="my-2"><i className="fab fas fa-envelope mr-2"></i> <a href="mailto:tip@paradiplomacia.org">tip@paradiplomacia.org</a></p>
                        <p className="my-2">Revista “Trabajos de Investigación en Paradiplomacia”</p>
                        <p className="my-2">ISSN 1853-9939</p>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <hr></hr>
          <ul className="social-buttons">
            <li>
              <a target="_blank" rel="noopener noreferrer" href="mailto:info@paradiplomacia.org">
                <Button
                  className="btn-icon btn-neutral mr-1"
                  color="twitter"
                  size="md"
                >
                  <i className="fab fas fa-envelope"></i>
                </Button>
              </a>
            </li>
            <li>
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/paradiplomacia">
                <Button
                  className="btn-icon btn-neutral mr-1"
                  color="twitter"
                  size="md"
                >
                  <i className="fab fa-instagram"></i>
                </Button>
              </a>
            </li>
            <li>
              <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/paradiplomacia.org/">
                <Button
                  className="btn-icon btn-neutral mr-1"
                  color="linkedin"
                  size="md"
                >
                  <i className="fab fa-facebook-square"></i>
                </Button>
              </a>
            </li>
            <li>
              <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Paradiplomacia">
                <Button
                  className="btn-icon btn-neutral mr-1"
                  color="twitter"
                  size="md"
                >
                  <i className="fab fa-twitter-square"></i>
                </Button>
              </a>
            </li>
            <li>
              <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/paradiplomacia.org/about/">
                <Button
                  className="btn-icon btn-neutral mr-1"
                  color="linkedin"
                  size="md"
                >
                  <i className="fab fa-linkedin"></i>
                </Button>
              </a>
            </li>
          </ul>
          <div className="copyright pull-center" id="copyright">
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            Copyright © {new Date().getFullYear()} | <a href="/legal">Términos y Condiciones de Uso</a> | Sitio hecho por <a href="https://francocirulli.com" target="_blank">Franco Cirulli</a> | Ilustraciones <a href="https://www.instagram.com/redolaf" target="_blank">Alfredo Cáceres</a>.
          </div>
        </Container>
      </footer>
    </>
  );
}

export default FooterSocial;
