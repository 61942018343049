import React, { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import FixedTransparentNavbar from "pincelit/components/Navbar.js";
import BlogPostsHeader from "pincelit/components/PageHeader.js";
import Footer from "pincelit/components/Footer.js";

// Context
import { DataContextConsumer } from "services/dataContext.js";

// Utils
import Functions from 'pincelit/utils/functions.js';

function Books() {
  // hardcode values
  const booksSize = 24;

  // states
  const [booksDisplay, setBooksDisplay] = useState(booksSize - 1);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [languageFilter, setLanguageFilter] = useState([]);
  const [countryFilter, setCountryFilter] = useState([]);
  const category = decodeURI(window.location.pathname.split("/").pop());
  const languagesStatic = {
    'EN': 'Inglés',
    'ES': 'Español',
    'PR': 'Portugués',
    'FR': 'Francés',
  }
  const staticFlags = {
    'Afganistán': 'AF',
    'Albania': 'AL',
    'Alemania': 'DE',
    'Andorra': 'AD',
    'Angola': 'AO',
    'Anguila': 'AI',
    'Antártida': 'AQ',
    'Antigua y Barbuda': 'AG',
    'Arabia Saudita': 'SA',
    'Argelia': 'DZ',
    'Argentina': 'AR',
    'Armenia': 'AM',
    'Aruba': 'AW',
    'Australia': 'AU',
    'Austria': 'AT',
    'Azerbaiyán': 'AZ',
    'Bélgica': 'BE',
    'Bahamas': 'BS',
    'Bahrein': 'BH',
    'Bangladesh': 'BD',
    'Barbados': 'BB',
    'Belice': 'BZ',
    'Benín': 'BJ',
    'Bhután': 'BT',
    'Bielorrusia': 'BY',
    'Birmania': 'MM',
    'Bolivia': 'BO',
    'Bosnia y Herzegovina': 'BA',
    'Botsuana': 'BW',
    'Brasil': 'BR',
    'Brunéi': 'BN',
    'Bulgaria': 'BG',
    'Burkina Faso': 'BF',
    'Burundi': 'BI',
    'Cabo Verde': 'CV',
    'Camboya': 'KH',
    'Camerún': 'CM',
    'Canadá': 'CA',
    'Chad': 'TD',
    'Chile': 'CL',
    'China': 'CN',
    'Chipre': 'CY',
    'Ciudad del Vaticano': 'VA',
    'Colombia': 'CO',
    'Comoras': 'KM',
    'República del Congo': 'CG',
    'República Democrática del Congo': 'CD',
    'Corea del Norte': 'KP',
    'Corea del Sur': 'KR',
    'Costa de Marfil': 'CI',
    'Costa Rica': 'CR',
    'Croacia': 'HR',
    'Cuba': 'CU',
    'Curazao': 'CW',
    'Dinamarca': 'DK',
    'Dominica': 'DM',
    'Ecuador': 'EC',
    'Egipto': 'EG',
    'El Salvador': 'SV',
    'Emiratos Árabes Unidos': 'AE',
    'Eritrea': 'ER',
    'Eslovaquia': 'SK',
    'Eslovenia': 'SI',
    'España': 'ES',
    'Estados Unidos de América': 'US',
    'Estonia': 'EE',
    'Etiopía': 'ET',
    'Filipinas': 'PH',
    'Finlandia': 'FI',
    'Fiyi': 'FJ',
    'Francia': 'FR',
    'Gabón': 'GA',
    'Gambia': 'GM',
    'Georgia': 'GE',
    'Ghana': 'GH',
    'Gibraltar': 'GI',
    'Granada': 'GD',
    'Grecia': 'GR',
    'Groenlandia': 'GL',
    'Guadalupe': 'GP',
    'Guam': 'GU',
    'Guatemala': 'GT',
    'Guayana Francesa': 'GF',
    'Guernsey': 'GG',
    'Guinea': 'GN',
    'Guinea Ecuatorial': 'GQ',
    'Guinea-Bissau': 'GW',
    'Guyana': 'GY',
    'Haití': 'HT',
    'Honduras': 'HN',
    'Hong kong': 'HK',
    'Hungría': 'HU',
    'India': 'IN',
    'Indonesia': 'ID',
    'Irán': 'IR',
    'Irak': 'IQ',
    'Irlanda': 'IE',
    'Isla Bouvet': 'BV',
    'Isla de Man': 'IM',
    'Isla de Navidad': 'CX',
    'Isla Norfolk': 'NF',
    'Islandia': 'IS',
    'Islas Bermudas': 'BM',
    'Islas Caimán': 'KY',
    'Islas Cocos (Keeling)': 'CC',
    'Islas Cook': 'CK',
    'Islas de Åland': 'AX',
    'Islas Feroe': 'FO',
    'Islas Georgias del Sur y Sandwich del Sur': 'GS',
    'Islas Heard y McDonald': 'HM',
    'Islas Maldivas': 'MV',
    'Islas Malvinas': 'FK',
    'Islas Marianas del Norte': 'MP',
    'Islas Marshall': 'MH',
    'Islas Pitcairn': 'PN',
    'Islas Salomón': 'SB',
    'Islas Turcas y Caicos': 'TC',
    'Islas Ultramarinas Menores de Estados Unidos': 'UM',
    'Islas Vírgenes Británicas': 'VG',
    'Islas Vírgenes de los Estados Unidos': 'VI',
    'Israel': 'IL',
    'Italia': 'IT',
    'Jamaica': 'JM',
    'Japón': 'JP',
    'Jersey': 'JE',
    'Jordania': 'JO',
    'Kazajistán': 'KZ',
    'Kenia': 'KE',
    'Kirguistán': 'KG',
    'Kiribati': 'KI',
    'Kuwait': 'KW',
    'Líbano': 'LB',
    'Laos': 'LA',
    'Lesoto': 'LS',
    'Letonia': 'LV',
    'Liberia': 'LR',
    'Libia': 'LY',
    'Liechtenstein': 'LI',
    'Lituania': 'LT',
    'Luxemburgo': 'LU',
    'México': 'MX',
    'Mónaco': 'MC',
    'Macao': 'MO',
    'Macedônia': 'MK',
    'Madagascar': 'MG',
    'Malasia': 'MY',
    'Malawi': 'MW',
    'Mali': 'ML',
    'Malta': 'MT',
    'Marruecos': 'MA',
    'Martinica': 'MQ',
    'Mauricio': 'MU',
    'Mauritania': 'MR',
    'Mayotte': 'YT',
    'Micronesia': 'FM',
    'Moldavia': 'MD',
    'Mongolia': 'MN',
    'Montenegro': 'ME',
    'Montserrat': 'MS',
    'Mozambique': 'MZ',
    'Namibia': 'NA',
    'Nauru': 'NR',
    'Nepal': 'NP',
    'Nicaragua': 'NI',
    'Niger': 'NE',
    'Nigeria': 'NG',
    'Niue': 'NU',
    'Noruega': 'NO',
    'Nueva Caledonia': 'NC',
    'Nueva Zelanda': 'NZ',
    'Omán': 'OM',
    'Países Bajos': 'NL',
    'Pakistán': 'PK',
    'Palau': 'PW',
    'Palestina': 'PS',
    'Panamá': 'PA',
    'Papúa Nueva Guinea': 'PG',
    'Paraguay': 'PY',
    'Perú': 'PE',
    'Polinesia Francesa': 'PF',
    'Polonia': 'PL',
    'Portugal': 'PT',
    'Puerto Rico': 'PR',
    'Qatar': 'QA',
    'Reino Unido': 'GB',
    'República Centroafricana': 'CF',
    'República Checa': 'CZ',
    'República Dominicana': 'DO',
    'República de Sudán del Sur': 'SS',
    'Reunión': 'RE',
    'Ruanda': 'RW',
    'Rumanía': 'RO',
    'Rusia': 'RU',
    'Sahara Occidental': 'EH',
    'Samoa': 'WS',
    'Samoa Americana': 'AS',
    'San Bartolomé': 'BL',
    'San Cristóbal y Nieves': 'KN',
    'San Marino': 'SM',
    'San Martín (Francia)': 'MF',
    'San Pedro y Miquelón': 'PM',
    'San Vicente y las Granadinas': 'VC',
    'Santa Elena': 'SH',
    'Santa Lucía': 'LC',
    'Santo Tomé y Príncipe': 'ST',
    'Senegal': 'SN',
    'Serbia': 'RS',
    'Seychelles': 'SC',
    'Sierra Leona': 'SL',
    'Singapur': 'SG',
    'Sint Maarten': 'SX',
    'Siria': 'SY',
    'Somalia': 'SO',
    'Sri lanka': 'LK',
    'Sudáfrica': 'ZA',
    'Sudán': 'SD',
    'Suecia': 'SE',
    'Suiza': 'CH',
    'Surinám': 'SR',
    'Svalbard y Jan Mayen': 'SJ',
    'Swazilandia': 'SZ',
    'Tayikistán': 'TJ',
    'Tailandia': 'TH',
    'Taiwán': 'TW',
    'Tanzania': 'TZ',
    'Territorio Británico del Océano Índico': 'IO',
    'Territorios Australes y Antárticas Franceses': 'TF',
    'Timor Oriental': 'TL',
    'Togo': 'TG',
    'Tokelau': 'TK',
    'Tonga': 'TO',
    'Trinidad y Tobago': 'TT',
    'Tunez': 'TN',
    'Turkmenistán': 'TM',
    'Turquía': 'TR',
    'Tuvalu': 'TV',
    'Ucrania': 'UA',
    'Uganda': 'UG',
    'Uruguay': 'UY',
    'Uzbekistán': 'UZ',
    'Vanuatu': 'VU',
    'Venezuela': 'VE',
    'Vietnam': 'VN',
    'Wallis y Futuna': 'WF',
    'Yemen': 'YE',
    'Yibuti': 'DJ',
    'Zambia': 'ZM',
    'Zimbabue': 'ZW',
  };
  const staticInfo = {
    libros: {
      title: 'Libros Global & Smart',
      breadcrumb: [],
      subtitle: 'Una biblioteca en expansión.',
      description: 'En esta sección encontrarás presentaciones de libros y publicaciones relacionados con el desarrollo global de los gobiernos locales para ser más inteligentes, humanos y sustentables. Son bienvenidos libros para sumar a nuestra biblioteca, envíanos el tuyo a info@paradiplomacia.org.'
    },
    globalcities: {
      title: 'Global Cities',
      breadcrumb: [{ name: 'Libros', url: '/libros' }],
      subtitle: 'Tenemos mucho para leer.',
      description: 'Paradiplomacia.org apoya la difusión de libros y toda emisión editorial que contribuya a promover ciudades globales, inteligentes más humanas y sustentables.'
    },
    smartcities: {
      title: 'Smart Cities',
      breadcrumb: [{ name: 'Libros', url: '/libros' }],
      subtitle: 'Libros para estimular la innovación.',
      description: 'En esta sección encontrará presentaciones de libros y publicaciones relacionados con el desarrollo inteligente de las ciudades para ser más eficaces, humanas y sustentables.'
    },
  };
  const getFilteredBookData = (data) => {
    let filteredData = [];
    data && data.map(item =>
      Functions.checkResultsFromPath(item.Categoría, 'libros')
      && (Functions.checkFilterResults(item, 'Idioma', languageFilter))
      && (Functions.checkFilterResults(item, 'País', countryFilter))
      && (Functions.checkFilterResults(item, 'Categoría', categoryFilter))
      &&
      filteredData.push(item)
    )
    return filteredData;
  }

  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("blog-posts");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <DataContextConsumer>
      {context => (
        <React.Fragment>
          <FixedTransparentNavbar />
          <div className="wrapper magazine-styles">
            <BlogPostsHeader breadcrumbItems={staticInfo[category.replace(/\s/g, '')].breadcrumb} image={require("assets/img/default—banner@2x.png")} title={staticInfo[category.replace(/\s/g, '')].title} subtitle={staticInfo[category.replace(/\s/g, '')].subtitle} />
            <div className="section">
              <Container>
                <Row>
                  <Col md="12">
                    <p className="section-description">
                      {staticInfo[category.replace(/\s/g, '')].description}
                    </p>
                  </Col>
                </Row>
                  {
                    context.data.libros ?
                      <React.Fragment>
                        <Row className="mb-5">
                          <Col sm="12">
                            <p>Filtros</p>
                          </Col>
                          <Col sm="12" md="4">
                            <Select
                              className="react-select react-select-info mt-2"
                              classNamePrefix="react-select"
                              onChange={value => setCategoryFilter(value)}
                              closeMenuOnSelect={true}
                              isMulti
                              placeholder="Categoría"
                              name=""
                              options={
                                (Functions.getUniqueValuesForFilters(context.data.libros, 'Categoría')).map(item => {
                                  return { value: item, label: item }
                                })
                              }
                            ></Select>
                          </Col>
                          <Col sm="12" md="4">
                            <Select
                              className="react-select react-select-info mt-2"
                              onChange={value => { setLanguageFilter(value)}}
                              classNamePrefix="react-select"
                              closeMenuOnSelect={true}
                              placeholder="Idiomas"
                              isMulti
                              name=""
                              options={
                                (Functions.getUniqueValuesForFilters(context.data.libros, 'Idioma')).map(item => {
                                  return { value: item, label: languagesStatic[item] }
                                })
                              }
                            ></Select>
                          </Col>
                          <Col sm="12" md="4">
                            <Select
                              className="react-select react-select-info mt-2"
                              onChange={value => setCountryFilter(value)}
                              classNamePrefix="react-select"
                              closeMenuOnSelect={true}
                              placeholder="País"
                              isMulti
                              name=""
                              options={
                                (Functions.getUniqueValuesForFilters(context.data.libros, 'País')).map(item => {
                                  return { value: item, label: item}
                                })
                              }
                            ></Select>
                          </Col>
                          <Col sm="12">
                            <hr></hr>
                          </Col>
                        </Row>
                        <Row>
                          {
                            (getFilteredBookData(context.data.libros)).map((item, i) =>
                              i <= (booksDisplay)
                              &&
                              <Col key={i} xs="6" sm="6" md="3" lg="2">
                                <Card className="card-plain card-blog">
                                  <div className="card-image">
                                    <Link to={`/libro/${Functions.getNumbers(item["Marca temporal"])}`}>
                                      <div
                                        className="img rounded img-raised books-img"
                                        style={{
                                          backgroundImage:
                                            `url(${Functions.getDriveImages(item.Portada)})`
                                        }}
                                      ></div>
                                    </Link>
                                  </div>
                                  <CardBody>
                                    <Badge color="primary mr-1">{item.Categoría}</Badge>
                                    {
                                      item.País && staticFlags[item.País] &&
                                      <img
                                        alt="..."
                                        className="img book-flag mr-1"
                                        src={require(`assets/img/flags/${staticFlags[item.País]}.png`)}
                                      ></img>
                                    }
                                    <Badge color="info px-2 book-lang">{item.Idioma}</Badge>

                                    <CardTitle>
                                      <h5 className="book-title" small>
                                        <Link to={`/libro/${Functions.getNumbers(item["Marca temporal"])}`}>{item.Título}</Link>
                                      </h5>
                                      <p className="card-description">
                                        {item.Subtítulo}
                                        <Link to={`/libro/${Functions.getNumbers(item["Marca temporal"])}`}>
                                          <Button
                                            size="sm"
                                            className="btn-round mt-0 mb-0"
                                            color="primary"
                                            outline
                                          >
                                            Leer más
                                      </Button>
                                        </Link>
                                      </p>
                                    </CardTitle>
                                  </CardBody>
                                </Card>
                              </Col>
                            )
                          }
                        </Row>
                      </React.Fragment>
                       :
                      <Row>
                        <Col className="ml-auto mr-auto" md="8">
                          <div className="section-description text-center">
                            <h4>No se encontraron libros</h4>
                          </div>
                        </Col>
                      </Row>
                  }
                {
                  (booksDisplay + 1) < (getFilteredBookData(context.data.libros)).length &&
                  <Row>
                    <Col className="ml-auto mr-auto" md="8">
                      <div className="section-description text-center">
                        <Button
                          className="btn-round"
                          color="info"
                          onClick={() => { setBooksDisplay(booksDisplay + booksSize) }}
                        >
                          Ver más
                      </Button>
                      </div>
                    </Col>
                  </Row>
                }
              </Container>
            </div>
            <Footer />
            {/* <Dots customLoading={context.loading} color={'#FFFFFF'} /> */}
          </div>
        </React.Fragment>
      )}
    </DataContextConsumer>
  );
}

export default Books;
