import React from "react";
import { Link } from "react-router-dom";
import Parser from 'html-react-parser';
import Linkify from 'react-linkify';
import ReadingTime from 'reading-time';

// share btns
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Badge,
  Container,
  Row,
  Col,
} from "reactstrap";

// Player
import ReactPlayer from 'react-player'

// core components
import FixedTransparentNavbar from "pincelit/components/Navbar.js";
import Footer from "pincelit/components/Footer.js";
import AboutUsHeader from "pincelit/components/PageHeader.js";

// Context
import { DataContextConsumer } from "services/dataContext.js";

// Utils
import Functions from 'pincelit/utils/functions.js';

function Article() {
  // states
  const newId = decodeURI(window.location.pathname.split("/").pop());
  const staticInfo = {
    novedad: {
      title: 'Novedad',
      section: 'news',
      subtitle: 'Las Ciudades se mueven.',
      breadcrumb: [{ name: 'Novedades', url: '/novedades' }],
      description: ''
    },
    evento: {
      title: 'Evento',
      section: 'news',
      subtitle: 'Las Ciudades se mueven.',
      breadcrumb: [{ name: 'Novedades', url: '/novedades' }],
      description: ''
    },
    experienciasglobalcities: {
      title: 'Experiencias Global Cities',
      section: 'experience',
      breadcrumb: [{ name: 'Novedades', url: '/novedades' }, { name: 'Experiencias Global Cities', url: '/novedades/experiencias%20global%20cities' }],
      subtitle: 'Todos podemos cooperar.',
      description: ''
    },
    globalcity: {
      title: 'Global City',
      section: 'news',
      breadcrumb: [{ name: 'Novedades', url: '/novedades' }, { name: 'Global City', url: '/novedades/global%20city' }],
      subtitle: '',
      description: ''
    },
    smartcity: {
      title: 'Smart City',
      section: 'news',
      breadcrumb: [{ name: 'Novedades', url: '/novedades' }, { name: 'Smart City', url: '/novedades/smart%20city' }],
      subtitle: '',
      description: ''
    },
    tecnología: {
      title: 'Tecnología',
      section: 'news',
      breadcrumb: [{ name: 'Novedades', url: '/novedades' }, { name: 'Tecnología', url: '/novedades/tecnología' }],
      subtitle: 'Hoy todo se puede hacer.',
      description: 'La tecnología permite medir la gestión, conectar a los ciudadanos, cuidarlos, moverlos, sorprenderlos, etc... en toda ciudad y en todo momento. La sociedad del conocimiento al alcance local.'
    },
    experienciassmartcities: {
      title: 'Experiencias Smart Cities',
      section: 'experience',
      breadcrumb: [{ name: 'Novedades', url: '/novedades' }, { name: 'Experiencias Smart Cities', url: '/novedades/experiencias%20smart%20cities' }],
      subtitle: 'La inteligencia al servicio de las personas.',
      description: 'Las ciudades inteligentes son ciudades globales que observan lo que pasa en el mundo y conocen lo que pasa en su territorio.'
    },
  };

  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("blog-posts");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <DataContextConsumer>
      {context => (
        <React.Fragment>
          <FixedTransparentNavbar />
          <div className="wrapper article-styles">
            {/* <BlogPostsHeader image={categoryData.Imagen} title={`Noticias`} subtitle={categoryData.Categoría} /> */}
            {
              context.data.novedades &&
              (context.data.novedades).map(item =>
                newId === Functions.getNumbers(item["Marca temporal"]) &&
                  <React.Fragment>
                  <AboutUsHeader breadcrumbItems={staticInfo[((item.Categoría).toLowerCase()).replace(/\s/g, '')].breadcrumb} image={require("assets/img/default—banner@2x.png")} title={item.Título} subtitle={item.Subtítulo} />
                  {
                    staticInfo[((item.Categoría).toLowerCase()).replace(/\s/g, '')].section === 'news' &&
                    <div className="section">
                      <div className="section pt-0 pb-0">
                        <Container>
                          <Row>
                            <Col className="ml-auto mr-auto" md="12">
                              <p>
                                Publicado el {item["Marca temporal"] && Functions.removeHour(item["Marca temporal"])} - {item.Descripción && `${Math.ceil(ReadingTime(item.Descripción).minutes)} min de lectura`}
                              </p>
                              <img
                                alt="..."
                                className="img rounded img-raised"
                                src={Functions.getDriveImages(item.Portada)}
                              ></img>
                              <h5 className="mt-3">
                                {item["Bajada de Imagen"]}
                              </h5>
                            </Col>
                            {
                              item.Video &&
                              <Col className="ml-auto mr-auto mt-5" md="12">
                                <ReactPlayer width='100%' url={item.Video} />
                              </Col>
                            }
                            <Col className="ml-auto mr-auto" md="12">
                              <p className="mt-5">
                                <Linkify properties={{ target: '_blank' }}>
                                  {Parser(item.Descripción + '')}
                                </Linkify>
                              </p>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                      <div className="section section-blog-info">
                        <Container>
                          <Row>
                            <Col className="ml-auto mr-auto" md="12">
                              <Row>
                                <Col md="12">
                                  <hr />
                                  {
                                    item["Fuente de la Información"] &&
                                    <p className="mt-5">
                                      Fuente:
                                    <a className="ml-1" href={item["Link original de la nota"]} rel="noopener noreferrer" target="_blank" >
                                        {Parser(item["Fuente de la Información"] + '')}
                                      </a>
                                    </p>
                                  }
                                  {
                                    item.Autor &&
                                    <p>
                                      Autor:
                                    <Link to={`/busqueda/${item.Autor}`}>
                                        <Badge color="primary" className="mr-1 ml-1">{item.Autor}</Badge>
                                      </Link>
                                    </p>
                                  }
                                  <EmailShareButton url={window.location.href}>
                                    <Button
                                      className="btn-round btn-icon mr-1"
                                      color="google"
                                    >
                                      <i className="now-ui-icons ui-1_email-85"></i>
                                    </Button>
                                  </EmailShareButton>
                                  <FacebookShareButton url={window.location.href}>
                                    <Button
                                      className="btn-round btn-icon mr-1"
                                      color="facebook"
                                    >
                                      <i className="fab fa-facebook-square"></i>
                                    </Button>
                                  </FacebookShareButton>
                                  <TwitterShareButton url={window.location.href}>
                                    <Button
                                      className="btn-round btn-icon mr-1"
                                      color="twitter"
                                    >
                                      <i className="fab fa-twitter"></i>
                                    </Button>
                                  </TwitterShareButton>
                                  <WhatsappShareButton url={window.location.href}>
                                    <Button
                                      className="btn-round btn-icon mr-1"
                                      color="whatsapp"
                                    >
                                      <i className="fab fa-whatsapp"></i>
                                    </Button>
                                  </WhatsappShareButton>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="ml-auto mr-auto mt-5" md="12">
                              <h4 className="subtitle">
                                También podrá interesarte
                              </h4>
                            </Col>
                            {
                              context.data.revistas &&
                              (context.data.revistas).slice(0, 3).map((item, i) =>
                                <Col data-js={item.Id} key={item.Id} sm="12" md="6" lg="4">
                                  <Card className="card-plain card-blog">
                                    <div className="card-image">
                                      <Link to={`/revista/${Functions.getNumbers(item["Marca temporal"])}`}>
                                        <div
                                          className="img rounded img-raised article-wrapper"
                                        >
                                          <div
                                            className="article-cover"
                                            style={{
                                              backgroundImage:
                                                "url(" + Functions.getDriveImages(item.Portada) + ")"
                                            }}
                                          ></div>
                                          <div
                                            className="article-img"
                                            style={{
                                              backgroundImage:
                                                "url(" + Functions.getDriveImages(item.Portada) + ")"
                                            }}
                                          >

                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <CardBody>
                                      <Badge color="info">{item.Categoría}</Badge>
                                      <CardTitle tag="h4">
                                        <Link to={`/revista/${Functions.getNumbers(item["Marca temporal"])}`}>
                                          {item.Título}
                                        </Link>
                                      </CardTitle>
                                      <Link to={`/revista/${Functions.getNumbers(item["Marca temporal"])}`}>
                                        <Button
                                          size="Sm"
                                          className="btn-round mt-0 mb-0"
                                          color="primary"
                                          outline
                                        >
                                          Leer más
                                      </Button>
                                      </Link>
                                    </CardBody>
                                  </Card>
                                </Col>
                              )
                            }
                          </Row>
                        </Container>
                      </div>
                    </div>
                  }
                  {
                    staticInfo[((item.Categoría).toLowerCase()).replace(/\s/g, '')].section === 'experience' &&
                    <div className="section">
                      <div className="section pt-0 pb-0">
                        <Container>
                          <Row>
                            <Col className="ml-auto mr-auto" md="12">
                              <p>
                                Publicado el {item["Marca temporal"] && Functions.removeHour(item["Marca temporal"])}
                              </p>
                              <img
                                alt="..."
                                className="img rounded img-raised"
                                src={Functions.getDriveImages(item.Portada)}
                              ></img>
                              <h5 className="mt-3">
                                {item["Bajada de Imagen"]}
                              </h5>
                            </Col>
                            {
                              item.Video &&
                              <Col className="ml-auto mr-auto mt-5" md="12">
                                <ReactPlayer width='100%' url={item.Video} />
                              </Col>
                            }
                            <Col className="ml-auto mr-auto" md="4">
                              <div className="info info-hover">
                                <div className="icon icon-info icon-circle ml-2">
                                  <i class="fas fa-city"></i>
                                </div>
                                <h4 className="info-title text-left">Problemática:</h4>
                                <p className="description text-left">
                                  <Linkify properties={{ target: '_blank' }}>
                                    {Parser(item.Problemática + '')}
                                  </Linkify>
                                </p>
                              </div>
                            </Col>
                            <Col className="ml-auto mr-auto" md="4">
                              <div className="info info-hover">
                                <div className="icon icon-info icon-circle ml-2">
                                  <i class="fas fa-flask"></i>
                                </div>
                                <h4 className="info-title text-left">Solución:</h4>
                                <p className="description text-left">
                                  <Linkify properties={{ target: '_blank' }}>
                                    {Parser(item.Solución + '')}
                                  </Linkify>
                                </p>
                              </div>
                            </Col>
                            <Col className="ml-auto mr-auto" md="4">
                              <div className="info info-hover">
                                <div className="icon icon-info icon-circle ml-2">
                                  <i class="fas fa-globe"></i>
                                </div>
                                <h4 className="info-title text-left">Impacto:</h4>
                                <p className="description text-left">
                                  <Linkify properties={{ target: '_blank' }}>
                                    {Parser(item.Impacto + '')}
                                  </Linkify>
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                      <div className="section section-blog-info">
                        <Container>
                          <Row>
                            <Col className="ml-auto mr-auto" md="12">
                              <Row>
                                <Col md="12">
                                  <hr />
                                  {
                                    item["Fuente de la Información"] &&
                                    <p className="mt-5">
                                      Fuente:
                                    <a className="ml-1" href={item["Link original de la nota"]} rel="noopener noreferrer" target="_blank" >
                                        {Parser(item["Fuente de la Información"] + '')}
                                      </a>
                                    </p>
                                  }
                                  {
                                    item.Autor &&
                                    <p>
                                      Autor:
                                    <Link to={`/busqueda/${item.Autor}`}>
                                        <Badge color="primary" className="mr-1 ml-1">{item.Autor}</Badge>
                                      </Link>
                                    </p>
                                  }
                                  <EmailShareButton url={window.location.href}>
                                    <Button
                                      className="btn-round btn-icon mr-1"
                                      color="google"
                                    >
                                      <i className="now-ui-icons ui-1_email-85"></i>
                                    </Button>
                                  </EmailShareButton>
                                  <FacebookShareButton url={window.location.href}>
                                    <Button
                                      className="btn-round btn-icon mr-1"
                                      color="facebook"
                                    >
                                      <i className="fab fa-facebook-square"></i>
                                    </Button>
                                  </FacebookShareButton>
                                  <TwitterShareButton url={window.location.href}>
                                    <Button
                                      className="btn-round btn-icon mr-1"
                                      color="twitter"
                                    >
                                      <i className="fab fa-twitter"></i>
                                    </Button>
                                  </TwitterShareButton>
                                  <WhatsappShareButton url={window.location.href}>
                                    <Button
                                      className="btn-round btn-icon mr-1"
                                      color="whatsapp"
                                    >
                                      <i className="fab fa-whatsapp"></i>
                                    </Button>
                                  </WhatsappShareButton>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="ml-auto mr-auto mt-5" md="12">
                              <h4 className="subtitle">
                                También podrá interesarte
                              </h4>
                            </Col>
                            {
                              context.data.revistas &&
                              (context.data.revistas).slice(0, 3).map((item, i) =>
                                <Col data-js={item.Id} key={item.Id} sm="12" md="6" lg="4">
                                  <Card className="card-plain card-blog">
                                    <div className="card-image">
                                      <Link to={`/revista/${Functions.getNumbers(item["Marca temporal"])}`}>
                                        <div
                                          className="img rounded img-raised article-wrapper"
                                        >
                                          <div
                                            className="article-cover"
                                            style={{
                                              backgroundImage:
                                                "url(" + Functions.getDriveImages(item.Portada) + ")"
                                            }}
                                          ></div>
                                          <div
                                            className="article-img"
                                            style={{
                                              backgroundImage:
                                                "url(" + Functions.getDriveImages(item.Portada) + ")"
                                            }}
                                          >

                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <CardBody>
                                      <Badge color="info">{item.Categoría}</Badge>
                                      <CardTitle tag="h4">
                                        <Link to={`/revista/${Functions.getNumbers(item["Marca temporal"])}`}>
                                          {item.Título}
                                        </Link>
                                      </CardTitle>
                                      <Link to={`/revista/${Functions.getNumbers(item["Marca temporal"])}`}>
                                        <Button
                                          size="Sm"
                                          className="btn-round mt-0 mb-0"
                                          color="primary"
                                          outline
                                        >
                                          Leer más
                                      </Button>
                                      </Link>
                                    </CardBody>
                                  </Card>
                                </Col>
                              )
                            }
                          </Row>
                        </Container>
                      </div>
                    </div>
                  }
                </React.Fragment>
              )
            }
            <Footer />
            {/* <Dots customLoading={context.loading} color={'#FFFFFF'} /> */}
          </div>
        </React.Fragment>
      )}
    </DataContextConsumer>
  );
}

export default Article;
